import * as actionTypes from './actionTypes';
import { ProductActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import {
  Product,
  ProductStatus,
  ProductType,
  SetProduct,
} from '../../domain/Product';
import {
  ProductAdminFilterParams,
  ProductFilterParams,
  PublicProductFilterTypes,
} from './service';
import { ProductPromotion } from '../../domain/ProductPromotion';
import _ from 'lodash';
import { BundleSectionLists } from 'src/domain/BundleSectionLists';

export type AssetBlob = {
  blob: Blob;
  assetId: number;
};

export type ProductStateType = {
  publicProductListParams: ProductFilterParams;
  adminProductListParams: ProductAdminFilterParams;
  productList: ListResults<Product> | null;
  productListLoading: boolean;
  productListError: HttpError;
  productListUpdateNeeded: boolean;
  initiateProductsFetchingLoading: boolean;
  initiateProductsFetchingError: HttpError;
  productCreateLoading: boolean;
  productCreateError: HttpError;
  productCreateSuccess: boolean;
  createdProduct: Product | null;
  productUpdateLoading: boolean;
  productUpdateError: HttpError;
  productUpdateSuccess: boolean;
  product: Product | null;
  productLoading: boolean;
  productError: HttpError;
  productDeleteLoading: boolean;
  productDeleteError: HttpError;
  productDeleteSuccess: boolean;
  publicPopularProducts: Product[];
  publicPopularProductsError: HttpError;
  publicPopularProductsLoading: boolean;
  publicProducts: ListResults<Product> | null;
  publicProductsError: HttpError;
  publicProductsLoading: boolean;
  publicProduct: Product | null;
  publicProductError: HttpError;
  publicProductLoading: boolean;
  userProductList: ListResults<Product> | null;
  userProductListLoading: boolean;
  userProductListError: HttpError;
  userProductListUpdateNeeded: boolean;
  userAllProductsCount: number;
  userAllProductsCountLoading: boolean;
  userAllProductsCountError: HttpError;
  userAllProductsCountUpdateNeeded: boolean;
  updateProductFilesLoading: boolean;
  updateProductFilesError: HttpError;
  updateProductFilesSuccess: boolean;
  publishProductLoading: boolean;
  publishProductError: HttpError;
  publishProductSuccess: boolean;
  depublishProductLoading: boolean;
  depublishProductError: HttpError;
  depublishProductSuccess: boolean;
  recommendedProducts: Product[];
  recommendedProductsLoading: boolean;
  recommendedProductsError: HttpError;
  userLikedProductList: ListResults<Product> | null;
  userLikedProductListLoading: boolean;
  userLikedProductListError: HttpError;
  publicUserCreationsList: ListResults<Product> | null;
  publicUserCreationsListError: HttpError;
  publicUserCreationsListLoading: boolean;
  verifiedPurchaseProductListLoading: boolean;
  verifiedPurchaseProductList: ListResults<Product> | null;
  verifiedPurchaseProductListError: HttpError;
  updateStudioIoFileLoading: boolean;
  updateStudioIoFileSuccess: boolean;
  updateStudioIoFileError: HttpError;
  updateInstructionsFileLoading: boolean;
  updateInstructionsFileSuccess: boolean;
  updateInstructionsFileError: HttpError;
  updateLdrFileLoading: boolean;
  updateLdrFileSuccess: boolean;
  updateLdrFileError: HttpError;
  updatePartsFileLoading: boolean;
  updatePartsFileSuccess: boolean;
  updatePartsFileError: HttpError;
  setsSuggestions: Product[] | null;
  setsSuggestionsError: HttpError;
  setsSuggestionsLoading: boolean;
  selfAddedProductsListLoading: boolean;
  selfAddedProductsList: ListResults<Product> | null;
  selfAddedProductsListError: HttpError;
  purchasedProductPromotion: ProductPromotion | null;
  downloadAllAssetsLoading: Record<string, boolean>;
  downloadAllAssetsSuccess: boolean;
  downloadAllAssetsFail: Record<string, HttpError>;
  publicSetProducts: ListResults<Product> | null;
  publicSetProductsError: HttpError;
  publicSetProductsLoading: boolean;
  updateAdditionalInstructionsFileLoading: boolean;
  updateAdditionalInstructionsFileSuccess: boolean;
  updateAdditionalInstructionsFileError: HttpError;
  setOptionsLoading: boolean;
  setOptions: ListResults<Product> | null;
  setOptionsError: HttpError;
  publicSetProductsForSale: ListResults<Product> | null;
  publicSetProductsForSaleError: HttpError;
  publicSetProductsForSaleLoading: boolean;
  userSetOptions: ListResults<Product> | null;
  userSetOptionsError: HttpError;
  userSetOptionsLoading: boolean;
  userSetAltList: ListResults<Product> | null;
  userSetAltListError: HttpError;
  userSetAltListLoading: boolean;
  publicBundles: ListResults<Product> | null;
  publicBundlesError: HttpError;
  publicBundlesLoading: boolean;
  publicBundleSections: BundleSectionLists | null;
  publicBundleSectionsError: HttpError;
  publicBundleSectionsLoading: boolean;
  userPurchasedProducts: Product[];
  userPurchasedProductsError: HttpError;
  userPurchasedProductsLoading: boolean;
  allSets: SetProduct[];
  allSetsError: HttpError;
  allSetsLoading: boolean;
  bundleSetProduct: Product | null;
  bundleSetProductError: HttpError;
  bundleSetProductLoading: boolean;
  exploreSets: Product[];
  exploreSetsLoading: boolean;
  exploreSetsError: HttpError;
  toggleUserLikedProductError: HttpError;
  toggleUserLikedProductLoading: boolean;
  toggleUserLikedProduct: Product | null;
  toggleUserLikedProductSuccess: boolean;
  userLikedProductsError: HttpError;
  userLikedProductsLoading: boolean;
  userLikedProducts: Product[] | null;
};

export type ProductActionType = ProductStateType & {
  type: ProductActionTypes;
  assetThumbnailBlob: AssetBlob;
  id: string;
};

export const initialState: ProductStateType = {
  publicProductListParams: {
    type: [],
    tier: [],
    price: [],
    numberOfParts: [],
    category: [],
    themes: [],
    sets: [],
    search: '',
    sortBy: 'product.createdAt',
    sortDirection: 'ASC',
    page: 0,
    limit: 16,
    filterType: PublicProductFilterTypes.PRICE_HIGH_TO_LOW,
    isInUserCollection: false,
  },
  adminProductListParams: {
    sortBy: 'product.title',
    search: '',
    sortDirection: 'ASC',
    page: 0,
    limit: 10,
    types: [
      ProductType.MOC,
      ProductType.ALTERNATE,
      ProductType.MOC_WITH_PARTS,
      ProductType.SET,
      ProductType.BUNDLE,
    ],
    statuses: [ProductStatus.PUBLISHED, ProductStatus.DRAFT],
    bigBuy: undefined,
  },
  productList: null,
  productListLoading: true,
  productListError: null,
  productListUpdateNeeded: false,
  initiateProductsFetchingLoading: false,
  initiateProductsFetchingError: null,
  productCreateLoading: false,
  productCreateError: null,
  productCreateSuccess: false,
  createdProduct: null,
  productUpdateLoading: false,
  productUpdateError: null,
  productUpdateSuccess: false,
  product: null,
  productLoading: false,
  productError: null,
  productDeleteLoading: false,
  productDeleteError: null,
  productDeleteSuccess: false,
  publicPopularProducts: [],
  publicPopularProductsError: null,
  publicPopularProductsLoading: false,
  publicProducts: null,
  publicProductsError: null,
  publicProductsLoading: false,
  publicProduct: null,
  publicProductError: null,
  publicProductLoading: false,
  userProductList: null,
  userProductListLoading: false,
  userProductListError: null,
  userAllProductsCount: 0,
  userAllProductsCountLoading: false,
  userAllProductsCountError: null,
  userAllProductsCountUpdateNeeded: false,
  userProductListUpdateNeeded: false,
  updateProductFilesLoading: false,
  updateProductFilesError: null,
  updateProductFilesSuccess: false,
  publishProductLoading: false,
  publishProductError: null,
  publishProductSuccess: false,
  depublishProductLoading: false,
  depublishProductError: null,
  depublishProductSuccess: false,
  recommendedProducts: [],
  recommendedProductsLoading: false,
  recommendedProductsError: null,
  userLikedProductList: null,
  userLikedProductListLoading: true,
  userLikedProductListError: null,
  publicUserCreationsList: null,
  publicUserCreationsListError: null,
  publicUserCreationsListLoading: false,
  verifiedPurchaseProductList: null,
  verifiedPurchaseProductListLoading: false,
  verifiedPurchaseProductListError: null,
  updateStudioIoFileLoading: false,
  updateStudioIoFileSuccess: false,
  updateStudioIoFileError: null,
  updateInstructionsFileLoading: false,
  updateInstructionsFileSuccess: false,
  updateInstructionsFileError: null,
  updateLdrFileLoading: false,
  updateLdrFileSuccess: false,
  updateLdrFileError: null,
  updatePartsFileLoading: false,
  updatePartsFileSuccess: false,
  updatePartsFileError: null,
  setsSuggestions: null,
  setsSuggestionsError: null,
  setsSuggestionsLoading: false,
  selfAddedProductsList: null,
  selfAddedProductsListLoading: false,
  selfAddedProductsListError: null,
  purchasedProductPromotion: null,
  downloadAllAssetsLoading: {},
  downloadAllAssetsSuccess: false,
  downloadAllAssetsFail: {},
  publicSetProducts: null,
  publicSetProductsError: null,
  publicSetProductsLoading: false,
  updateAdditionalInstructionsFileLoading: false,
  updateAdditionalInstructionsFileSuccess: false,
  updateAdditionalInstructionsFileError: null,
  setOptionsLoading: false,
  setOptions: null,
  setOptionsError: null,
  publicSetProductsForSale: null,
  publicSetProductsForSaleError: null,
  publicSetProductsForSaleLoading: false,
  userSetOptions: null,
  userSetOptionsError: null,
  userSetOptionsLoading: false,
  userSetAltList: null,
  userSetAltListError: null,
  userSetAltListLoading: false,
  publicBundles: null,
  publicBundlesError: null,
  publicBundlesLoading: false,
  publicBundleSections: null,
  publicBundleSectionsError: null,
  publicBundleSectionsLoading: false,
  userPurchasedProducts: [],
  userPurchasedProductsError: null,
  userPurchasedProductsLoading: false,
  allSets: [],
  allSetsError: null,
  allSetsLoading: false,
  bundleSetProduct: null,
  bundleSetProductError: null,
  bundleSetProductLoading: false,
  exploreSets: [],
  exploreSetsLoading: false,
  exploreSetsError: null,
  toggleUserLikedProductLoading: false,
  toggleUserLikedProduct: null,
  toggleUserLikedProductSuccess: false,
  toggleUserLikedProductError: null,
  userLikedProductsLoading: false,
  userLikedProducts: null,
  userLikedProductsError: null,
};

const fetchUserSetOptionsStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  userSetOptionsLoading: true,
});

const fetchUserSetOptionsSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  userSetOptionsLoading: false,
  userSetOptions: action.userSetOptions,
});

const fetchUserSetOptionsFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  userSetOptionsLoading: false,
  userSetOptionsError: action.userSetOptionsError,
});

const fetchUserAltListStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  userSetOptionsLoading: true,
});

const fetchUserAltListSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  userSetAltListLoading: false,
  userSetAltList: action.userSetAltList,
});

const fetchUserAltListFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  userSetAltListLoading: false,
  userSetAltListError: action.userSetAltListError,
});

const resetUserAltList = (state: ProductStateType): ProductStateType => ({
  ...state,
  userSetAltListLoading: false,
  userSetAltListError: null,
  userSetAltList: null,
});

const fetchProductListStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  productListLoading: true,
  productCreateSuccess: false,
  productUpdateSuccess: false,
  productListUpdateNeeded: false,
});

const fetchProductListSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  productListLoading: false,
  productList: action.productList,
  productListUpdateNeeded: false,
});

const fetchProductListFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  productListLoading: false,
  productListError: action.productListError,
});

const fetchUserLikedProductListStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  userLikedProductListLoading: true,
});

const fetchUserLikedProductListSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  userLikedProductListLoading: false,
  userLikedProductList: action.userLikedProductList,
});

const fetchUserLikedProductListFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  userLikedProductListLoading: false,
  userLikedProductListError: action.userLikedProductListError,
});

const initiateProductsFetchingStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  initiateProductsFetchingLoading: true,
});

const initiateProductsFetchingSuccess = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  initiateProductsFetchingLoading: false,
});

const initiateProductsFetchingFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  initiateProductsFetchingLoading: false,
  initiateProductsFetchingError: action.initiateProductsFetchingError,
});

const fetchProductStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  productLoading: true,
  productCreateSuccess: false,
  productUpdateSuccess: false,
});

const fetchProductSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  productError: null,
  productLoading: false,
  product: action.product,
});

const fetchProductFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  productLoading: false,
  productError: action.productError,
});

const createProductStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  productCreateLoading: true,
});

const createProductSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  productCreateLoading: false,
  productCreateError: null,
  productCreateSuccess: true,
  productListUpdateNeeded: true,
  createdProduct: action.createdProduct,
});

const createProductFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  productCreateLoading: false,
  productCreateError: action.productCreateError,
});

const updateProductStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  productUpdateLoading: true,
});

const updateProductSuccess = (state: ProductStateType): ProductStateType => ({
  ...state,
  productUpdateLoading: false,
  productUpdateSuccess: true,
  productListUpdateNeeded: true,
  productUpdateError: null,
});

const updateProductFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  productUpdateLoading: false,
  productUpdateError: action.productUpdateError,
});

const fetchPublicPopularProductsStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  publicPopularProductsLoading: true,
});

const fetchPublicPopularProductsSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicPopularProductsLoading: false,
  publicPopularProducts: action.publicPopularProducts,
});

const fetchPublicPopularProductsFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicPopularProductsLoading: false,
  publicPopularProductsError: action.publicPopularProductsError,
});

const fetchExploreSetsStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  exploreSetsLoading: true,
});

const fetchExploreSetsSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  exploreSetsLoading: false,
  exploreSets: action.exploreSets,
});

const fetchExploreSetsFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  exploreSetsLoading: false,
  exploreSetsError: action.exploreSetsError,
});

const fetchPublicProductsStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  publicProductsLoading: true,
});

const fetchPublicProductsSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicProductsLoading: false,
  publicProducts: action.publicProducts,
});

const fetchPublicProductsFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicProductsLoading: false,
  publicProductsError: action.publicProductsError,
});

const fetchPublicProductStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  publicProductLoading: true,
});

const fetchPublicProductSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicProductLoading: false,
  publicProduct: action.publicProduct,
});

const fetchPublicProductFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicProductLoading: false,
  publicProductError: action.publicProductError,
});

const resetPublicProduct = (state: ProductStateType): ProductStateType => ({
  ...state,
  publicProduct: null,
  publicProductLoading: false,
  publicProductError: null,
});

const deleteProductStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  productDeleteLoading: true,
});

const deleteProductSuccess = (state: ProductStateType): ProductStateType => ({
  ...state,
  productDeleteLoading: false,
  productDeleteSuccess: true,
  productListUpdateNeeded: true,
  userProductListUpdateNeeded: true,
  userAllProductsCountUpdateNeeded: true,
});

const deleteProductFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  productDeleteLoading: false,
  productDeleteError: action.productDeleteError,
});

const fetchUserProductListStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  userProductListLoading: true,
  userProductListUpdateNeeded: false,
});

const fetchUserProductListSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  userProductListLoading: false,
  userProductList: action.userProductList,
});

const fetchUserProductListFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  userProductListLoading: false,
  userProductListError: action.userProductListError,
});

const fetchAllUserProductsCountStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  userAllProductsCountLoading: true,
  userAllProductsCountUpdateNeeded: false,
});

const fetchAllUserProductsCountSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  userAllProductsCountLoading: false,
  userAllProductsCount: action.userAllProductsCount,
});

const fetchAllUserProductsCountFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  userAllProductsCountLoading: false,
  userAllProductsCountError: action.userAllProductsCountError,
});

const updateProductFilesStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  updateProductFilesLoading: true,
});

const updateProductFilesSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  updateProductFilesLoading: false,
  updateProductFilesSuccess: true,
  product: action.product,
  createdProduct: action.product,
});

const updateProductFilesFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  updateProductFilesLoading: false,
  updateProductFilesError: action.updateProductFilesError,
});

const updateStudioIoFileStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  updateStudioIoFileLoading: true,
});

const updateStudioIoFileSuccess = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  updateStudioIoFileLoading: false,
  updateStudioIoFileSuccess: true,
});

const updateStudioIoFileFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  updateStudioIoFileLoading: false,
  updateStudioIoFileError: action.updateStudioIoFileError,
});

const updateInstructionsFileStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  updateInstructionsFileLoading: true,
});

const updateInstructionsFileSuccess = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  updateInstructionsFileLoading: false,
  updateInstructionsFileSuccess: true,
});

const updateInstructionsFileFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  updateInstructionsFileLoading: false,
  updateInstructionsFileError: action.updateStudioIoFileError,
});

const updateAdditionalInstructionsFileStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  updateAdditionalInstructionsFileLoading: true,
});

const updateAdditionalInstructionsFileSuccess = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  updateAdditionalInstructionsFileLoading: false,
  updateAdditionalInstructionsFileSuccess: true,
});

const updateAdditionalInstructionsFileFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  updateAdditionalInstructionsFileLoading: false,
  updateAdditionalInstructionsFileError: action.updateStudioIoFileError,
});

const updateLdrFileStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  updateLdrFileLoading: true,
});

const updateLdrFileSuccess = (state: ProductStateType): ProductStateType => ({
  ...state,
  updateLdrFileLoading: false,
  updateLdrFileSuccess: true,
});

const updateLdrFileFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  updateLdrFileLoading: false,
  updateLdrFileError: action.updateStudioIoFileError,
});

const updatePartsFileStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  updatePartsFileLoading: true,
});

const updatePartsFileSuccess = (state: ProductStateType): ProductStateType => ({
  ...state,
  updatePartsFileLoading: false,
  updatePartsFileSuccess: true,
});

const updatePartsFileFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  updatePartsFileLoading: false,
  updatePartsFileError: action.updateStudioIoFileError,
});

const publishProductStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  publishProductLoading: true,
});

const publishProductSuccess = (state: ProductStateType): ProductStateType => ({
  ...state,
  publishProductLoading: false,
  publishProductSuccess: true,
  userProductListUpdateNeeded: true,
  productListUpdateNeeded: true,
});

const publishProductFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publishProductLoading: false,
  publishProductError: action.publishProductError,
});

const resetPublishProduct = (state: ProductStateType): ProductStateType => ({
  ...state,
  publishProductLoading: false,
  publishProductSuccess: false,
  publishProductError: null,
});

const depublishProductStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  depublishProductLoading: true,
});

const depublishProductSuccess = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  depublishProductLoading: false,
  depublishProductSuccess: true,
  userProductListUpdateNeeded: true,
  productListUpdateNeeded: true,
});

const depublishProductFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  depublishProductLoading: false,
  depublishProductError: action.publishProductError,
});

const setPubicProductListParams = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicProductListParams: action.publicProductListParams,
});

const setAdminProductListParams = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  adminProductListParams: action.adminProductListParams,
});

const fetchRecommendedProductsStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  recommendedProductsLoading: true,
});

const fetchRecommendedProductsSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  recommendedProductsError: null,
  recommendedProductsLoading: false,
  recommendedProducts: action.recommendedProducts,
});

const fetchRecommendedProductsFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  recommendedProductsLoading: false,
  recommendedProductsError: action.recommendedProductsError,
});

const fetchPublicUserCreationsListStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  publicUserCreationsListLoading: true,
});

const fetchPublicUserCreationsListSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicUserCreationsListLoading: false,
  publicUserCreationsList: action.publicUserCreationsList,
});

const fetchPublicUserCreationsListFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicUserCreationsListLoading: false,
  publicUserCreationsListError: action.publicUserCreationsListError,
});

const fetchSetsSuggestionsStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  setsSuggestionsLoading: true,
});

const fetchSetsSuggestionsSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  setsSuggestions: action.setsSuggestions,
  setsSuggestionsLoading: false,
  setsSuggestionsError: null,
});

const fetchSetsSuggestionsFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  setsSuggestionsError: action.setsSuggestionsError,
  setsSuggestionsLoading: false,
});

const fetchVerifiedPurchaseProductListStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  verifiedPurchaseProductListLoading: true,
});

const fetchVerifiedPurchaseProductListSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  verifiedPurchaseProductListLoading: false,
  verifiedPurchaseProductList: action.verifiedPurchaseProductList,
});

const fetchVerifiedPurchaseProductListFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  verifiedPurchaseProductListLoading: false,
  verifiedPurchaseProductListError: action.verifiedPurchaseProductListError,
});

const fetchSelfAddedProductsListStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  selfAddedProductsListLoading: true,
});

const fetchSelfAddedProductsListSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  selfAddedProductsListLoading: false,
  selfAddedProductsList: action.selfAddedProductsList,
});

const fetchSelfAddedProductsListFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  selfAddedProductsListLoading: false,
  selfAddedProductsListError: action.selfAddedProductsListError,
});

const fetchSetOptionsStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  setOptionsLoading: true,
});

const fetchSetOptionsSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  setOptions:
    state.setOptions && action.setOptions
      ? {
          total: action.setOptions?.total ?? 0,
          result: _.uniqBy(
            [
              ...state.setOptions.result,
              ...action.setOptions.result,
            ] as Product[],
            'id',
          ),
        }
      : action.setOptions,
  setOptionsLoading: false,
  setOptionsError: null,
});

const fetchSetOptionsFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  setOptionsError: action.setOptionsError,
  setOptionsLoading: false,
});

const resetFetchSetOptions = (state: ProductStateType): ProductStateType => ({
  ...state,
  setOptionsLoading: false,
  setOptions: null,
  setOptionsError: null,
});

const resetSetsSuggestions = (state: ProductStateType): ProductStateType => ({
  ...state,
  setsSuggestions: null,
  setsSuggestionsLoading: false,
  setsSuggestionsError: null,
});
const addPurchasedProductPromotion = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => {
  if (state.product && action.purchasedProductPromotion) {
    return {
      ...state,
      product: {
        ...state.product,
        productPromotions: [
          ...state.product.productPromotions,
          action.purchasedProductPromotion,
        ],
      },
    };
  }

  if (state.createdProduct && action.purchasedProductPromotion) {
    return {
      ...state,
      createdProduct: {
        ...state.createdProduct,
        productPromotions: [
          ...state.createdProduct.productPromotions,
          action.purchasedProductPromotion,
        ],
      },
    };
  }

  return state;
};

const resetCreatedProduct = (state: ProductStateType): ProductStateType => ({
  ...state,
  productCreateLoading: false,
  productCreateError: null,
  productCreateSuccess: false,
  createdProduct: null,
});

const resetCreateAndUpdateProductSuccess = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  productCreateSuccess: false,
  productUpdateSuccess: false,
});

const resetFetchProduct = (state: ProductStateType): ProductStateType => ({
  ...state,
  product: null,
  productError: null,
  productLoading: false,
});

const resetUpdateProductFiles = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  updateProductFilesLoading: false,
  updateProductFilesError: null,
  updateProductFilesSuccess: false,
  updateStudioIoFileLoading: false,
  updateStudioIoFileSuccess: false,
  updateStudioIoFileError: null,
  updateInstructionsFileLoading: false,
  updateInstructionsFileSuccess: false,
  updateInstructionsFileError: null,
  updateLdrFileLoading: false,
  updateLdrFileSuccess: false,
  updateLdrFileError: null,
  updatePartsFileLoading: false,
  updatePartsFileSuccess: false,
  updatePartsFileError: null,
  updateAdditionalInstructionsFileLoading: false,
  updateAdditionalInstructionsFileSuccess: false,
  updateAdditionalInstructionsFileError: null,
});

const downloadAllAssetsStart = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => {
  const loadings = state.downloadAllAssetsLoading;
  loadings[action.id] = true;

  return { ...state, downloadAllAssetsLoading: loadings };
};

const downloadAllAssetsSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => {
  const loadings = state.downloadAllAssetsLoading;
  loadings[action.id] = false;

  return {
    ...state,
    downloadAllAssetsLoading: loadings,
    downloadAllAssetsSuccess: true,
  };
};

const downloadAllAssetsFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => {
  const loadings = state.downloadAllAssetsLoading;
  loadings[action.id] = false;

  return {
    ...state,
    downloadAllAssetsLoading: loadings,
    downloadAllAssetsFail: action.downloadAllAssetsFail,
  };
};

const fetchPublicSetProductsStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  publicSetProductsLoading: true,
});

const fetchPublicSetProductsSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicSetProductsLoading: false,
  publicSetProducts: action.publicSetProducts,
});

const fetchPublicSetProductsFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicSetProductsLoading: false,
  publicSetProductsError: action.publicSetProductsError,
});

const fetchSetProductsForSaleStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  publicSetProductsForSaleLoading: true,
});

const fetchSetProductsForSaleSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicSetProductsForSaleLoading: false,
  publicSetProductsForSale: action.publicSetProductsForSale,
});

const fetchSetProductsForSaleFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicSetProductsForSaleLoading: false,
  publicSetProductsForSaleError: action.publicSetProductsForSaleError,
});

const publicBundlesStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  publicBundlesLoading: true,
});

const publicBundlesSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicBundlesLoading: false,
  publicBundles: action.publicBundles,
});

const publicBundlesFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicBundlesLoading: false,
  publicBundlesError: action.publicBundlesError,
});

const publicBundleSectionsStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  publicBundleSectionsLoading: true,
});

const publicBundleSectionsSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicBundleSectionsLoading: false,
  publicBundleSections: action.publicBundleSections,
});

const publicBundleSectionsFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  publicBundleSectionsLoading: false,
  publicBundleSectionsError: action.publicBundlesError,
});

const fetchUserPurchasedProductsStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  userPurchasedProductsLoading: true,
});

const fetchUserPurchasedProductsSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  userPurchasedProductsLoading: false,
  userPurchasedProducts: action.userPurchasedProducts,
});

const fetchUserPurchasedProductsFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  userPurchasedProductsLoading: false,
  userPurchasedProductsError: action.userPurchasedProductsError,
});

const resetProductStore = (state: ProductStateType): ProductStateType => ({
  ...initialState,
  adminProductListParams: state.adminProductListParams,
});

const completelyResetProductStore = (
  state: ProductStateType,
): ProductStateType => ({
  ...initialState,
  publicProducts: state.publicProducts,
  publicPopularProducts: state.publicPopularProducts,
  publicProduct: state.publicProduct,
  recommendedProducts: state.recommendedProducts,
});

const fetchAllSetsStart = (state: ProductStateType): ProductStateType => ({
  ...state,
  allSetsLoading: true,
});

const fetchAllSetsSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  allSetsLoading: false,
  allSets: action.allSets,
});

const fetchAllSetsFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  allSetsLoading: false,
  allSetsError: action.allSetsError,
});

const fetchBundleSetProductStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  bundleSetProductLoading: true,
});

const fetchBundleSetProductSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  bundleSetProductLoading: false,
  bundleSetProduct: action.bundleSetProduct,
});

const fetchBundleSetProductFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  bundleSetProductLoading: false,
  bundleSetProductError: action.bundleSetProductError,
});

const toggleUserLikedProductStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  toggleUserLikedProductLoading: true,
  toggleUserLikedProductSuccess: false,
});

const toggleUserLikedProductSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => {
  if (state.userLikedProducts && action.toggleUserLikedProduct) {
    const isProductLiked = !!state.userLikedProducts?.find(
      (product) => product.id === action.toggleUserLikedProduct?.id,
    );

    if (isProductLiked) {
      const filteredLikedProducts = state.userLikedProducts.filter(
        (product) => product.id !== action.toggleUserLikedProduct?.id,
      );

      return {
        ...state,
        toggleUserLikedProductLoading: false,
        toggleUserLikedProductSuccess: true,
        toggleUserLikedProduct: action.toggleUserLikedProduct,
        userLikedProducts: filteredLikedProducts,
      };
    }

    return {
      ...state,
      toggleUserLikedProductLoading: false,
      toggleUserLikedProductSuccess: true,
      toggleUserLikedProduct: action.toggleUserLikedProduct,
      userLikedProducts: [
        ...state.userLikedProducts,
        action.toggleUserLikedProduct,
      ],
    };
  }

  return {
    ...state,
    toggleUserLikedProductLoading: false,
    toggleUserLikedProduct: action.toggleUserLikedProduct,
    toggleUserLikedProductSuccess: true,
  };
};

const toggleUserLikedProductFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  toggleUserLikedProductLoading: false,
  toggleUserLikedProductError: action.toggleUserLikedProductError,
  toggleUserLikedProductSuccess: false,
});

const fetchUserLikedProductsStart = (
  state: ProductStateType,
): ProductStateType => ({
  ...state,
  userLikedProductsLoading: true,
});

const fetchUserLikedProductsSuccess = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  userLikedProductsLoading: false,
  userLikedProducts: action.userLikedProducts,
});

const fetchUserLikedProductsFail = (
  state: ProductStateType,
  action: ProductActionType,
): ProductStateType => ({
  ...state,
  userLikedProductsLoading: false,
  userLikedProductsError: action.userLikedProductsError,
});

const reducer = (state = initialState, action: ProductActionType) => {
  switch (action.type) {
    case actionTypes.SET_PUBLIC_PRODUCTS_PARAMS:
      return setPubicProductListParams(state, action);
    case actionTypes.SET_ADMIN_PRODUCTS_PARAMS:
      return setAdminProductListParams(state, action);
    case actionTypes.FETCH_PRODUCT_LIST_START:
      return fetchProductListStart(state);
    case actionTypes.FETCH_PRODUCT_LIST_SUCCESS:
      return fetchProductListSuccess(state, action);
    case actionTypes.FETCH_PRODUCT_LIST_FAIL:
      return fetchProductListFail(state, action);
    case actionTypes.FETCH_USER_LIKED_PRODUCT_LIST_START:
      return fetchUserLikedProductListStart(state);
    case actionTypes.FETCH_USER_LIKED_PRODUCT_LIST_SUCCESS:
      return fetchUserLikedProductListSuccess(state, action);
    case actionTypes.FETCH_USER_LIKED_PRODUCT_LIST_FAIL:
      return fetchUserLikedProductListFail(state, action);
    case actionTypes.INITIATE_PRODUCTS_FETCH_START:
      return initiateProductsFetchingStart(state);
    case actionTypes.INITIATE_PRODUCTS_FETCH_SUCCESS:
      return initiateProductsFetchingSuccess(state);
    case actionTypes.INITIATE_PRODUCTS_FETCH_FAIL:
      return initiateProductsFetchingFail(state, action);
    case actionTypes.FETCH_USER_PRODUCT_LIST_START:
      return fetchUserProductListStart(state);
    case actionTypes.FETCH_USER_PRODUCT_LIST_SUCCESS:
      return fetchUserProductListSuccess(state, action);
    case actionTypes.FETCH_USER_PRODUCT_LIST_FAIL:
      return fetchUserProductListFail(state, action);
    case actionTypes.FETCH_USER_ALL_PRODUCTS_COUNT_START:
      return fetchAllUserProductsCountStart(state);
    case actionTypes.FETCH_USER_ALL_PRODUCTS_COUNT_SUCCESS:
      return fetchAllUserProductsCountSuccess(state, action);
    case actionTypes.FETCH_USER_ALL_PRODUCTS_COUNT_FAIL:
      return fetchAllUserProductsCountFail(state, action);
    case actionTypes.FETCH_PRODUCT_START:
      return fetchProductStart(state);
    case actionTypes.FETCH_PRODUCT_SUCCESS:
      return fetchProductSuccess(state, action);
    case actionTypes.FETCH_PRODUCT_FAIL:
      return fetchProductFail(state, action);
    case actionTypes.CREATE_PRODUCT_START:
      return createProductStart(state);
    case actionTypes.CREATE_PRODUCT_SUCCESS:
      return createProductSuccess(state, action);
    case actionTypes.CREATE_PRODUCT_FAIL:
      return createProductFail(state, action);
    case actionTypes.UPDATE_PRODUCT_START:
      return updateProductStart(state);
    case actionTypes.UPDATE_PRODUCT_SUCCESS:
      return updateProductSuccess(state);
    case actionTypes.UPDATE_PRODUCT_FAIL:
      return updateProductFail(state, action);
    case actionTypes.FETCH_PUBLIC_POPULAR_PRODUCTS_START:
      return fetchPublicPopularProductsStart(state);
    case actionTypes.FETCH_PUBLIC_POPULAR_PRODUCTS_SUCCESS:
      return fetchPublicPopularProductsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_POPULAR_PRODUCTS_FAIL:
      return fetchPublicPopularProductsFail(state, action);
    case actionTypes.FETCH_PUBLIC_PRODUCTS_START:
      return fetchPublicProductsStart(state);
    case actionTypes.FETCH_PUBLIC_PRODUCTS_SUCCESS:
      return fetchPublicProductsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_PRODUCTS_FAIL:
      return fetchPublicProductsFail(state, action);
    case actionTypes.FETCH_PUBLIC_PRODUCT_START:
      return fetchPublicProductStart(state);
    case actionTypes.FETCH_PUBLIC_PRODUCT_SUCCESS:
      return fetchPublicProductSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_PRODUCT_FAIL:
      return fetchPublicProductFail(state, action);
    case actionTypes.RESET_PUBLIC_PRODUCT:
      return resetPublicProduct(state);
    case actionTypes.DELETE_PRODUCT_START:
      return deleteProductStart(state);
    case actionTypes.DELETE_PRODUCT_SUCCESS:
      return deleteProductSuccess(state);
    case actionTypes.DELETE_PRODUCT_FAIL:
      return deleteProductFail(state, action);
    case actionTypes.UPDATE_PRODUCT_FILES_START:
      return updateProductFilesStart(state);
    case actionTypes.UPDATE_PRODUCT_FILES_SUCCESS:
      return updateProductFilesSuccess(state, action);
    case actionTypes.UPDATE_PRODUCT_FILES_FAIL:
      return updateProductFilesFail(state, action);
    case actionTypes.UPDATE_STUDIO_IO_FILE_START:
      return updateStudioIoFileStart(state);
    case actionTypes.UPDATE_STUDIO_IO_FILE_SUCCESS:
      return updateStudioIoFileSuccess(state);
    case actionTypes.UPDATE_STUDIO_IO_FILE_FAIL:
      return updateStudioIoFileFail(state, action);
    case actionTypes.UPDATE_INSTRUCTIONS_FILE_START:
      return updateInstructionsFileStart(state);
    case actionTypes.UPDATE_INSTRUCTIONS_FILE_SUCCESS:
      return updateInstructionsFileSuccess(state);
    case actionTypes.UPDATE_INSTRUCTIONS_FILE_FAIL:
      return updateInstructionsFileFail(state, action);
    case actionTypes.UPDATE_ADDITIONAL_INSTRUCTIONS_FILE_START:
      return updateAdditionalInstructionsFileStart(state);
    case actionTypes.UPDATE_ADDITIONAL_INSTRUCTIONS_FILE_SUCCESS:
      return updateAdditionalInstructionsFileSuccess(state);
    case actionTypes.UPDATE_ADDITIONAL_INSTRUCTIONS_FILE_FAIL:
      return updateAdditionalInstructionsFileFail(state, action);
    case actionTypes.UPDATE_LDR_FILE_START:
      return updateLdrFileStart(state);
    case actionTypes.UPDATE_LDR_FILE_SUCCESS:
      return updateLdrFileSuccess(state);
    case actionTypes.UPDATE_LDR_FILE_FAIL:
      return updateLdrFileFail(state, action);
    case actionTypes.UPDATE_PARTS_FILE_START:
      return updatePartsFileStart(state);
    case actionTypes.UPDATE_PARTS_FILE_SUCCESS:
      return updatePartsFileSuccess(state);
    case actionTypes.UPDATE_PARTS_FILE_FAIL:
      return updatePartsFileFail(state, action);
    case actionTypes.PUBLISH_PRODUCT_START:
      return publishProductStart(state);
    case actionTypes.PUBLISH_PRODUCT_SUCCESS:
      return publishProductSuccess(state);
    case actionTypes.PUBLISH_PRODUCT_FAIL:
      return publishProductFail(state, action);
    case actionTypes.RESET_PUBLISH_PRODUCT:
      return resetPublishProduct(state);
    case actionTypes.DEPUBLISH_PRODUCT_START:
      return depublishProductStart(state);
    case actionTypes.DEPUBLISH_PRODUCT_SUCCESS:
      return depublishProductSuccess(state);
    case actionTypes.DEPUBLISH_PRODUCT_FAIL:
      return depublishProductFail(state, action);
    case actionTypes.FETCH_RECOMMENDED_PRODUCTS_START:
      return fetchRecommendedProductsStart(state);
    case actionTypes.FETCH_RECOMMENDED_PRODUCTS_SUCCESS:
      return fetchRecommendedProductsSuccess(state, action);
    case actionTypes.FETCH_RECOMMENDED_PRODUCTS_FAIL:
      return fetchRecommendedProductsFail(state, action);
    case actionTypes.FETCH_PUBLIC_USER_CREATIONS_LIST_START:
      return fetchPublicUserCreationsListStart(state);
    case actionTypes.FETCH_PUBLIC_USER_CREATIONS_LIST_SUCCESS:
      return fetchPublicUserCreationsListSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_USER_CREATIONS_LIST_FAIL:
      return fetchPublicUserCreationsListFail(state, action);
    case actionTypes.FETCH_VERIFIED_PURCHASE_LIST_START:
      return fetchVerifiedPurchaseProductListStart(state);
    case actionTypes.FETCH_VERIFIED_PURCHASE_LIST_SUCCESS:
      return fetchVerifiedPurchaseProductListSuccess(state, action);
    case actionTypes.FETCH_VERIFIED_PURCHASE_LIST_FAIL:
      return fetchVerifiedPurchaseProductListFail(state, action);
    case actionTypes.FETCH_SETS_SUGGESTIONS_START:
      return fetchSetsSuggestionsStart(state);
    case actionTypes.FETCH_SETS_SUGGESTIONS_SUCCESS:
      return fetchSetsSuggestionsSuccess(state, action);
    case actionTypes.FETCH_SETS_SUGGESTIONS_FAIL:
      return fetchSetsSuggestionsFail(state, action);
    case actionTypes.FETCH_SELF_ADDED_PRODUCTS_LIST_START:
      return fetchSelfAddedProductsListStart(state);
    case actionTypes.FETCH_SELF_ADDED_PRODUCTS_LIST_SUCCESS:
      return fetchSelfAddedProductsListSuccess(state, action);
    case actionTypes.FETCH_SELF_ADDED_PRODUCTS_LIST_FAIL:
      return fetchSelfAddedProductsListFail(state, action);
    case actionTypes.RESET_PARTICIPANTS_SUGGESTIONS:
      return resetSetsSuggestions(state);
    case actionTypes.ADD_PURCHASED_PRODUCT_PROMOTION:
      return addPurchasedProductPromotion(state, action);
    case actionTypes.DOWNLOAD_ALL_ASSETS_START:
      return downloadAllAssetsStart(state, action);
    case actionTypes.DOWNLOAD_ALL_ASSETS_SUCCESS:
      return downloadAllAssetsSuccess(state, action);
    case actionTypes.DOWNLOAD_ALL_ASSETS_FAIL:
      return downloadAllAssetsFail(state, action);
    case actionTypes.FETCH_PUBLIC_SET_PRODUCTS_START:
      return fetchPublicSetProductsStart(state);
    case actionTypes.FETCH_PUBLIC_SET_PRODUCTS_SUCCESS:
      return fetchPublicSetProductsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_SET_PRODUCTS_FAIL:
      return fetchPublicSetProductsFail(state, action);
    case actionTypes.FETCH_PUBLIC_SET_PRODUCTS_FOR_SALE_START:
      return fetchSetProductsForSaleStart(state);
    case actionTypes.FETCH_PUBLIC_SET_PRODUCTS_FOR_SALE_SUCCESS:
      return fetchSetProductsForSaleSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_SET_PRODUCTS_FOR_SALE_FAIL:
      return fetchSetProductsForSaleFail(state, action);
    case actionTypes.FETCH_PUBLIC_SET_OPTIONS_START:
      return fetchSetOptionsStart(state);
    case actionTypes.FETCH_PUBLIC_SET_OPTIONS_SUCCESS:
      return fetchSetOptionsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_SET_OPTIONS_FAIL:
      return fetchSetOptionsFail(state, action);
    case actionTypes.FETCH_USER_SET_OPTIONS_START:
      return fetchUserSetOptionsStart(state);
    case actionTypes.FETCH_USER_SET_OPTIONS_SUCCESS:
      return fetchUserSetOptionsSuccess(state, action);
    case actionTypes.FETCH_USER_SET_OPTIONS_FAIL:
      return fetchUserSetOptionsFail(state, action);
    case actionTypes.FETCH_USER_SET_ALTS_START:
      return fetchUserAltListStart(state);
    case actionTypes.FETCH_USER_SET_ALTS_SUCCESS:
      return fetchUserAltListSuccess(state, action);
    case actionTypes.FETCH_USER_SET_ALTS_FAIL:
      return fetchUserAltListFail(state, action);
    case actionTypes.FETCH_PUBLIC_BUNDLES_START:
      return publicBundlesStart(state);
    case actionTypes.FETCH_PUBLIC_BUNDLES_SUCCESS:
      return publicBundlesSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_BUNDLES_FAIL:
      return publicBundlesFail(state, action);
    case actionTypes.FETCH_PUBLIC_BUNDLE_SECTIONS_START:
      return publicBundleSectionsStart(state);
    case actionTypes.FETCH_PUBLIC_BUNDLE_SECTIONS_SUCCESS:
      return publicBundleSectionsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_BUNDLE_SECTIONS_FAIL:
      return publicBundleSectionsFail(state, action);
    case actionTypes.FETCH_USER_PURCHASED_PRODUCTS_START:
      return fetchUserPurchasedProductsStart(state);
    case actionTypes.FETCH_USER_PURCHASED_PRODUCTS_SUCCESS:
      return fetchUserPurchasedProductsSuccess(state, action);
    case actionTypes.FETCH_USER_PURCHASED_PRODUCTS_FAIL:
      return fetchUserPurchasedProductsFail(state, action);
    case actionTypes.RESET_FETCH_SET_OPTIONS:
      return resetFetchSetOptions(state);
    case actionTypes.RESET_CREATED_PRODUCT_STORE:
      return resetCreatedProduct(state);
    case actionTypes.RESET_CREATE_AND_UPDATE_PRODUCT_SUCCESS:
      return resetCreateAndUpdateProductSuccess(state);
    case actionTypes.RESET_FETCH_PRODUCT:
      return resetFetchProduct(state);
    case actionTypes.RESET_PRODUCT_STORE:
      return resetProductStore(state);
    case actionTypes.RESET_UPDATE_PRODUCT_FILES:
      return resetUpdateProductFiles(state);
    case actionTypes.RESET_USER_SET_ALTS:
      return resetUserAltList(state);
    case actionTypes.FETCH_ALL_SETS_START:
      return fetchAllSetsStart(state);
    case actionTypes.FETCH_ALL_SETS_SUCCESS:
      return fetchAllSetsSuccess(state, action);
    case actionTypes.FETCH_ALL_SETS_FAIL:
      return fetchAllSetsFail(state, action);
    case actionTypes.FETCH_BUNDLE_SET_PRODUCT_START:
      return fetchBundleSetProductStart(state);
    case actionTypes.FETCH_BUNDLE_SET_PRODUCT_SUCCESS:
      return fetchBundleSetProductSuccess(state, action);
    case actionTypes.FETCH_BUNDLE_SET_PRODUCT_FAIL:
      return fetchBundleSetProductFail(state, action);
    case actionTypes.FETCH_EXPLORE_SETS_START:
      return fetchExploreSetsStart(state);
    case actionTypes.FETCH_EXPLORE_SETS_SUCCESS:
      return fetchExploreSetsSuccess(state, action);
    case actionTypes.FETCH_EXPLORE_SETS_FAIL:
      return fetchExploreSetsFail(state, action);
    case actionTypes.TOGGLE_USER_LIKED_PRODUCT_START:
      return toggleUserLikedProductStart(state);
    case actionTypes.TOGGLE_USER_LIKED_PRODUCT_SUCCESS:
      return toggleUserLikedProductSuccess(state, action);
    case actionTypes.TOGGLE_USER_LIKED_PRODUCT_FAIL:
      return toggleUserLikedProductFail(state, action);
    case actionTypes.FETCH_USER_LIKED_PRODUCTS_START:
      return fetchUserLikedProductsStart(state);
    case actionTypes.FETCH_USER_LIKED_PRODUCTS_SUCCESS:
      return fetchUserLikedProductsSuccess(state, action);
    case actionTypes.FETCH_USER_LIKED_PRODUCTS_FAIL:
      return fetchUserLikedProductsFail(state, action);
    case actionTypes.LOGOUT:
      return completelyResetProductStore(state);
    default:
      return state;
  }
};

export default reducer;
