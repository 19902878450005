import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Product, SetProduct } from '../../domain/Product';
import { ProductAdminFilterParams, ProductFilterParams } from './service';
import { ProductPromotion } from '../../domain/ProductPromotion';
import { BundleSectionLists } from 'src/domain/BundleSectionLists';

export const fetchProductListStart = () => ({
  type: actionTypes.FETCH_PRODUCT_LIST_START,
});

export const fetchProductListSuccess = (productList: ListResults<Product>) => ({
  type: actionTypes.FETCH_PRODUCT_LIST_SUCCESS,
  productList,
});

export const fetchProductListFail = (productListError: HttpError) => ({
  type: actionTypes.FETCH_PRODUCT_LIST_FAIL,
  productListError,
});

export const fetchUserLikedProductListStart = () => ({
  type: actionTypes.FETCH_USER_LIKED_PRODUCT_LIST_START,
});

export const fetchUserLikedProductListSuccess = (
  userLikedProductList: ListResults<Product>,
) => ({
  type: actionTypes.FETCH_USER_LIKED_PRODUCT_LIST_SUCCESS,
  userLikedProductList,
});

export const fetchUserLikedProductListFail = (
  userLikedProductListError: HttpError,
) => ({
  type: actionTypes.FETCH_USER_LIKED_PRODUCT_LIST_FAIL,
  userLikedProductListError,
});

export const initiateProductsFetchStart = () => ({
  type: actionTypes.INITIATE_PRODUCTS_FETCH_START,
});

export const initiateProductsFetchSuccess = () => ({
  type: actionTypes.INITIATE_PRODUCTS_FETCH_SUCCESS,
});

export const initiateProductsFetchFail = (
  initiateProductsFetchError: HttpError,
) => ({
  type: actionTypes.INITIATE_PRODUCTS_FETCH_FAIL,
  initiateProductsFetchError,
});

export const fetchUserProductListStart = () => ({
  type: actionTypes.FETCH_USER_PRODUCT_LIST_START,
});

export const fetchUserProductListSuccess = (
  userProductList: ListResults<Product>,
) => ({
  type: actionTypes.FETCH_USER_PRODUCT_LIST_SUCCESS,
  userProductList,
});

export const fetchUserProductListFail = (userProductListError: HttpError) => ({
  type: actionTypes.FETCH_USER_PRODUCT_LIST_FAIL,
  userProductListError,
});

export const fetchPublicPopularProductsStart = () => ({
  type: actionTypes.FETCH_PUBLIC_POPULAR_PRODUCTS_START,
});

export const fetchPublicPopularProductsSuccess = (
  publicPopularProducts: Product[],
) => ({
  type: actionTypes.FETCH_PUBLIC_POPULAR_PRODUCTS_SUCCESS,
  publicPopularProducts,
});

export const fetchPublicPopularProductFail = (
  publicPopularProductsError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_POPULAR_PRODUCTS_FAIL,
  publicPopularProductsError,
});

export const fetchExploreSetsStart = () => ({
  type: actionTypes.FETCH_EXPLORE_SETS_START,
});

export const fetchExploreSetsSuccess = (exploreSets: Product[]) => ({
  type: actionTypes.FETCH_EXPLORE_SETS_SUCCESS,
  exploreSets,
});

export const fetchExploreSetsFail = (exploreSetsError: HttpError) => ({
  type: actionTypes.FETCH_EXPLORE_SETS_FAIL,
  exploreSetsError,
});

export const fetchPublicProductsStart = () => ({
  type: actionTypes.FETCH_PUBLIC_PRODUCTS_START,
});

export const fetchPublicProductsSuccess = (
  publicProducts: ListResults<Product> | null,
) => ({
  type: actionTypes.FETCH_PUBLIC_PRODUCTS_SUCCESS,
  publicProducts,
});

export const fetchPublicProductsFail = (publicProductsError: HttpError) => ({
  type: actionTypes.FETCH_PUBLIC_PRODUCTS_START,
  publicProductsError,
});

export const fetchPublicProductStart = () => ({
  type: actionTypes.FETCH_PUBLIC_PRODUCT_START,
});

export const fetchPublicProductSuccess = (publicProduct: Product | null) => ({
  type: actionTypes.FETCH_PUBLIC_PRODUCT_SUCCESS,
  publicProduct,
});

export const fetchPublicProductFail = (publicProductError: HttpError) => ({
  type: actionTypes.FETCH_PUBLIC_PRODUCT_FAIL,
  publicProductError,
});

export const resetPublicProduct = () => ({
  type: actionTypes.RESET_PUBLIC_PRODUCT,
});

export const fetchProductStart = () => ({
  type: actionTypes.FETCH_PRODUCT_START,
});

export const fetchProductSuccess = (product: Product) => ({
  type: actionTypes.FETCH_PRODUCT_SUCCESS,
  product,
});

export const fetchProductFail = (productError: HttpError) => ({
  type: actionTypes.FETCH_PRODUCT_FAIL,
  productError,
});

export const createProductStart = () => ({
  type: actionTypes.CREATE_PRODUCT_START,
});

export const createProductSuccess = (createdProduct: Product) => ({
  type: actionTypes.CREATE_PRODUCT_SUCCESS,
  createdProduct,
});

export const createProductFail = (productCreateError: HttpError) => ({
  type: actionTypes.CREATE_PRODUCT_FAIL,
  productCreateError,
});

export const updateProductStart = () => ({
  type: actionTypes.UPDATE_PRODUCT_START,
});

export const updateProductSuccess = () => ({
  type: actionTypes.UPDATE_PRODUCT_SUCCESS,
});

export const updateProductFail = (productUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_PRODUCT_FAIL,
  productUpdateError,
});

export const updateProductFilesStart = () => ({
  type: actionTypes.UPDATE_PRODUCT_FILES_START,
});

export const updateProductFilesSuccess = (product: Product) => ({
  type: actionTypes.UPDATE_PRODUCT_FILES_SUCCESS,
  product,
});

export const updateProductFilesFail = (updateProductFilesError: HttpError) => ({
  type: actionTypes.UPDATE_PRODUCT_FILES_FAIL,
  updateProductFilesError,
});

export const updateStudioIoFileStart = () => ({
  type: actionTypes.UPDATE_STUDIO_IO_FILE_START,
});

export const updateStudioIoFileSuccess = () => ({
  type: actionTypes.UPDATE_STUDIO_IO_FILE_SUCCESS,
});

export const updateStudioIoFileFail = (updateStudioIoFileError: HttpError) => ({
  type: actionTypes.UPDATE_STUDIO_IO_FILE_FAIL,
  updateStudioIoFileError,
});

export const updateInstructionsFileStart = () => ({
  type: actionTypes.UPDATE_INSTRUCTIONS_FILE_START,
});

export const updateInstructionsFileSuccess = () => ({
  type: actionTypes.UPDATE_INSTRUCTIONS_FILE_SUCCESS,
});

export const updateInstructionsFileFail = (
  updateInstructionsFileError: HttpError,
) => ({
  type: actionTypes.UPDATE_INSTRUCTIONS_FILE_FAIL,
  updateInstructionsFileError,
});

export const updateAdditionalInstructionsFileStart = () => ({
  type: actionTypes.UPDATE_ADDITIONAL_INSTRUCTIONS_FILE_START,
});

export const updateAdditionalInstructionsFileSuccess = () => ({
  type: actionTypes.UPDATE_ADDITIONAL_INSTRUCTIONS_FILE_SUCCESS,
});

export const updateAdditionalInstructionsFileFail = (
  updateAdditionalInstructionsFileError: HttpError,
) => ({
  type: actionTypes.UPDATE_ADDITIONAL_INSTRUCTIONS_FILE_FAIL,
  updateAdditionalInstructionsFileError,
});

export const updateLdrFileStart = () => ({
  type: actionTypes.UPDATE_LDR_FILE_START,
});

export const updateLdrFileSuccess = () => ({
  type: actionTypes.UPDATE_LDR_FILE_SUCCESS,
});

export const updateLdrFileFail = (updateLdrFileError: HttpError) => ({
  type: actionTypes.UPDATE_LDR_FILE_FAIL,
  updateLdrFileError,
});

export const updatePartsFileStart = () => ({
  type: actionTypes.UPDATE_PARTS_FILE_START,
});

export const updatePartsFileSuccess = () => ({
  type: actionTypes.UPDATE_PARTS_FILE_SUCCESS,
});

export const updatePartsFileFail = (updateLdrFileError: HttpError) => ({
  type: actionTypes.UPDATE_PARTS_FILE_FAIL,
  updateLdrFileError,
});

export const setPublicProductListParams = (
  publicProductListParams: ProductFilterParams,
) => ({
  type: actionTypes.SET_PUBLIC_PRODUCTS_PARAMS,
  publicProductListParams,
});

export const deleteProductStart = () => ({
  type: actionTypes.DELETE_PRODUCT_START,
});

export const deleteProductSuccess = () => ({
  type: actionTypes.DELETE_PRODUCT_SUCCESS,
});

export const deleteProductFail = (productDeleteError: HttpError) => ({
  type: actionTypes.DELETE_PRODUCT_FAIL,
  productDeleteError,
});

export const fetchAllUserProductsCountStart = () => ({
  type: actionTypes.FETCH_USER_ALL_PRODUCTS_COUNT_START,
});

export const fetchAllUserProductsCountSuccess = (
  userAllProductsCount: number,
) => ({
  type: actionTypes.FETCH_USER_ALL_PRODUCTS_COUNT_SUCCESS,
  userAllProductsCount,
});

export const fetchAllUserProductsCountFail = (
  userAllProductsCountError: HttpError,
) => ({
  type: actionTypes.FETCH_USER_ALL_PRODUCTS_COUNT_FAIL,
  userAllProductsCountError,
});

export const publishProductStart = () => ({
  type: actionTypes.PUBLISH_PRODUCT_START,
});

export const publishProductSuccess = () => ({
  type: actionTypes.PUBLISH_PRODUCT_SUCCESS,
});

export const publishProductFail = (publishProductError: HttpError) => ({
  type: actionTypes.PUBLISH_PRODUCT_FAIL,
  publishProductError,
});

export const depublishProductStart = () => ({
  type: actionTypes.DEPUBLISH_PRODUCT_START,
});

export const depublishProductSuccess = () => ({
  type: actionTypes.DEPUBLISH_PRODUCT_SUCCESS,
});

export const depublishProductFail = (depublishProductError: HttpError) => ({
  type: actionTypes.DEPUBLISH_PRODUCT_FAIL,
  depublishProductError,
});

export const fetchRecommendedProductsStart = () => ({
  type: actionTypes.FETCH_RECOMMENDED_PRODUCTS_START,
});

export const fetchRecommendedProductsSuccess = (
  recommendedProducts: Product[],
) => ({
  type: actionTypes.FETCH_RECOMMENDED_PRODUCTS_SUCCESS,
  recommendedProducts,
});

export const fetchRecommendedProductsFail = (
  recommendedProductsError: HttpError,
) => ({
  type: actionTypes.FETCH_RECOMMENDED_PRODUCTS_START,
  recommendedProductsError,
});

export const fetchPublicUserCreationsListStart = () => ({
  type: actionTypes.FETCH_PUBLIC_USER_CREATIONS_LIST_START,
});

export const fetchPublicUserCreationsListSuccess = (
  publicUserCreationsList: ListResults<Product> | null,
) => ({
  type: actionTypes.FETCH_PUBLIC_USER_CREATIONS_LIST_SUCCESS,
  publicUserCreationsList,
});

export const fetchPublicUserCreationsListFail = (
  publicUserCreationsListError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_USER_CREATIONS_LIST_START,
  publicUserCreationsListError,
});

export const fetchVerifiedPurchaseProductListStart = () => ({
  type: actionTypes.FETCH_VERIFIED_PURCHASE_LIST_START,
});

export const fetchVerifiedPurchaseProductListSuccess = (
  verifiedPurchaseProductList: ListResults<Product>,
) => ({
  type: actionTypes.FETCH_VERIFIED_PURCHASE_LIST_SUCCESS,
  verifiedPurchaseProductList,
});

export const fetchVerifiedPurchaseProductListFail = (
  verifiedPurchaseProductListError: HttpError,
) => ({
  type: actionTypes.FETCH_VERIFIED_PURCHASE_LIST_FAIL,
  verifiedPurchaseProductListError,
});

export const fetchSelfAddedProductsListStart = () => ({
  type: actionTypes.FETCH_SELF_ADDED_PRODUCTS_LIST_START,
});

export const fetchSelfAddedProductsListSuccess = (
  selfAddedProductsList: ListResults<Product>,
) => ({
  type: actionTypes.FETCH_SELF_ADDED_PRODUCTS_LIST_SUCCESS,
  selfAddedProductsList,
});

export const fetchSelfAddedProductsListFail = (
  selfAddedProductsListError: HttpError,
) => ({
  type: actionTypes.FETCH_SELF_ADDED_PRODUCTS_LIST_FAIL,
  selfAddedProductsListError,
});

export const fetchSetsSuggestionsStart = () => ({
  type: actionTypes.FETCH_SETS_SUGGESTIONS_START,
});

export const fetchSetsSuggestionsSuccess = (setsSuggestions: Product[]) => ({
  type: actionTypes.FETCH_SETS_SUGGESTIONS_SUCCESS,
  setsSuggestions,
});

export const fetchSetsSuggestionsFail = (setsSuggestionsError: HttpError) => ({
  type: actionTypes.FETCH_SETS_SUGGESTIONS_FAIL,
  setsSuggestionsError,
});

export const resetSetsSuggestions = () => ({
  type: actionTypes.RESET_PARTICIPANTS_SUGGESTIONS,
});

export const addPurchasedProductPromtion = (
  purchasedProductPromotion: ProductPromotion,
) => ({
  type: actionTypes.ADD_PURCHASED_PRODUCT_PROMOTION,
  purchasedProductPromotion,
});

export const downloadAllAssetsStart = (id?: string) => ({
  type: actionTypes.DOWNLOAD_ALL_ASSETS_START,
  id,
});

export const downloadAllAssetsSuccess = (id?: string) => ({
  type: actionTypes.DOWNLOAD_ALL_ASSETS_SUCCESS,
  id,
});

export const downloadAllAssetsFail = (downloadAllAssetsFail: HttpError) => ({
  type: actionTypes.DOWNLOAD_ALL_ASSETS_FAIL,
  downloadAllAssetsFail,
});

export const fetchSetProductsStart = () => ({
  type: actionTypes.FETCH_PUBLIC_SET_PRODUCTS_START,
});

export const fetchSetProductsSuccess = (
  publicSetProducts: ListResults<Product>,
) => ({
  type: actionTypes.FETCH_PUBLIC_SET_PRODUCTS_SUCCESS,
  publicSetProducts,
});

export const fetchSetProductsFail = (publicSetProductsError: HttpError) => ({
  type: actionTypes.FETCH_PUBLIC_SET_PRODUCTS_FAIL,
  publicSetProductsError,
});

export const fetchSetProductsForSaleStart = () => ({
  type: actionTypes.FETCH_PUBLIC_SET_PRODUCTS_FOR_SALE_START,
});

export const fetchSetProductsForSaleSuccess = (
  publicSetProductsForSale: ListResults<Product>,
) => ({
  type: actionTypes.FETCH_PUBLIC_SET_PRODUCTS_FOR_SALE_SUCCESS,
  publicSetProductsForSale,
});

export const fetchSetProductsForSaleFail = (
  publicSetProductsForSaleError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_SET_PRODUCTS_FOR_SALE_FAIL,
  publicSetProductsForSaleError,
});

export const fetchSetOptionsStart = () => ({
  type: actionTypes.FETCH_PUBLIC_SET_OPTIONS_START,
});

export const fetchSetOptionsSuccess = (setOptions: Product[]) => ({
  type: actionTypes.FETCH_PUBLIC_SET_OPTIONS_SUCCESS,
  setOptions,
});

export const fetchSetOptionsFail = (setOptionsError: HttpError) => ({
  type: actionTypes.FETCH_PUBLIC_SET_OPTIONS_FAIL,
  setOptionsError,
});

export const setAdminProductListParams = (
  adminProductListParams: ProductAdminFilterParams,
) => ({
  type: actionTypes.SET_ADMIN_PRODUCTS_PARAMS,
  adminProductListParams,
});

export const fetchUserSetOptionsStart = () => ({
  type: actionTypes.FETCH_USER_SET_OPTIONS_START,
});

export const fetchUserSetOptionsSuccess = (
  userSetOptions: ListResults<Product>,
) => ({
  type: actionTypes.FETCH_USER_SET_OPTIONS_SUCCESS,
  userSetOptions,
});

export const fetchUserSetOptionsFail = (userSetOptionsError: HttpError) => ({
  type: actionTypes.FETCH_USER_SET_OPTIONS_FAIL,
  userSetOptionsError,
});

export const fetchUserAltListStart = () => ({
  type: actionTypes.FETCH_USER_SET_ALTS_START,
});

export const resetUserAltList = () => ({
  type: actionTypes.RESET_USER_SET_ALTS,
});

export const fetchUserAltListSuccess = (
  userSetAltList: ListResults<Product>,
) => ({
  type: actionTypes.FETCH_USER_SET_ALTS_SUCCESS,
  userSetAltList,
});

export const fetchUserAltListFail = (userSetAltListError: HttpError) => ({
  type: actionTypes.FETCH_USER_SET_ALTS_FAIL,
  userSetAltListError,
});

export const fetchPublicBundlesStart = () => ({
  type: actionTypes.FETCH_PUBLIC_BUNDLES_START,
});

export const fetchPublicBundlesSuccess = (
  publicBundles: ListResults<Product> | null,
) => ({
  type: actionTypes.FETCH_PUBLIC_BUNDLES_SUCCESS,
  publicBundles,
});

export const fetchPublicBundlesFail = (publicBundlesError: HttpError) => ({
  type: actionTypes.FETCH_PUBLIC_BUNDLES_FAIL,
  publicBundlesError,
});

export const fetchPublicBundleSectionsStart = () => ({
  type: actionTypes.FETCH_PUBLIC_BUNDLE_SECTIONS_START,
});

export const fetchPublicBundleSectionsSuccess = (
  publicBundleSections: BundleSectionLists | null,
) => ({
  type: actionTypes.FETCH_PUBLIC_BUNDLE_SECTIONS_SUCCESS,
  publicBundleSections,
});

export const fetchPublicBundleSectionsFail = (
  publicBundlesError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_BUNDLE_SECTIONS_FAIL,
  publicBundlesError,
});

export const fetchUserPurchasedProductsStart = () => ({
  type: actionTypes.FETCH_USER_PURCHASED_PRODUCTS_START,
});

export const fetchUserPurchasedProductsSuccess = (
  userPurchasedProducts: Product[],
) => ({
  type: actionTypes.FETCH_USER_PURCHASED_PRODUCTS_SUCCESS,
  userPurchasedProducts,
});

export const fetchUserPurchasedProductsFail = (
  userPurchasedProductsError: HttpError,
) => ({
  type: actionTypes.FETCH_USER_PURCHASED_PRODUCTS_FAIL,
  userPurchasedProductsError,
});

export const resetFetchSetOptions = () => ({
  type: actionTypes.RESET_FETCH_SET_OPTIONS,
});

export const resetPublishProduct = () => ({
  type: actionTypes.RESET_PUBLISH_PRODUCT,
});

export const resetCreateAndUpdateProductSuccess = () => ({
  type: actionTypes.RESET_CREATE_AND_UPDATE_PRODUCT_SUCCESS,
});

export const resetCreatedProductStore = () => ({
  type: actionTypes.RESET_CREATED_PRODUCT_STORE,
});

export const resetProductStore = () => ({
  type: actionTypes.RESET_PRODUCT_STORE,
});

export const resetFetchProduct = () => ({
  type: actionTypes.RESET_FETCH_PRODUCT,
});

export const resetUpdateProductFiles = () => ({
  type: actionTypes.RESET_UPDATE_PRODUCT_FILES,
});

export const completelyResetProductStore = () => ({
  type: actionTypes.LOGOUT,
});

export const fetchAllSetsStart = () => ({
  type: actionTypes.FETCH_ALL_SETS_START,
});

export const fetchAllSetsSuccess = (allSets: SetProduct[]) => ({
  type: actionTypes.FETCH_ALL_SETS_SUCCESS,
  allSets,
});

export const fetchAllSetsFail = (allSetsError: HttpError) => ({
  type: actionTypes.FETCH_ALL_SETS_FAIL,
  allSetsError,
});

export const fetchBundleSetStart = () => ({
  type: actionTypes.FETCH_BUNDLE_SET_PRODUCT_START,
});

export const fetchBundleSetSuccess = (bundleSetProduct: Product) => ({
  type: actionTypes.FETCH_BUNDLE_SET_PRODUCT_SUCCESS,
  bundleSetProduct,
});

export const fetchBundleSetFail = (bundleSetProductError: HttpError) => ({
  type: actionTypes.FETCH_BUNDLE_SET_PRODUCT_FAIL,
  bundleSetProductError,
});

export const toggleUserLikedProductStart = () => ({
  type: actionTypes.TOGGLE_USER_LIKED_PRODUCT_START,
});

export const toggleUserLikedProductSuccess = (
  toggleUserLikedProduct: Product,
) => ({
  type: actionTypes.TOGGLE_USER_LIKED_PRODUCT_SUCCESS,
  toggleUserLikedProduct,
});

export const toggleUserLikedProductFail = (
  toggleUserLikedProductError: HttpError,
) => ({
  type: actionTypes.TOGGLE_USER_LIKED_PRODUCT_FAIL,
  toggleUserLikedProductError,
});

export const fetchUserLikedProductsStart = () => ({
  type: actionTypes.FETCH_USER_LIKED_PRODUCTS_START,
});

export const fetchUserLikedProductsSuccess = (
  userLikedProducts: Product[],
) => ({
  type: actionTypes.FETCH_USER_LIKED_PRODUCTS_SUCCESS,
  userLikedProducts,
});

export const fetchUserLikedProductsFail = (
  userLikedProductsError: HttpError,
) => ({
  type: actionTypes.FETCH_USER_LIKED_PRODUCTS_FAIL,
  userLikedProductsError,
});
