import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  PublicUserProfile,
  PublicUserSocialStats,
  User,
  UserCollectionParts,
  UserStats,
} from '../../domain/User';
import { ListResults } from '../../common/List/List';
import { CartItem } from '../../domain/Cart';

export const fetchUserStart = () => ({
  type: actionTypes.FETCH_USER_START,
});

export const fetchUserSuccess = (user: User) => ({
  type: actionTypes.FETCH_USER_SUCCESS,
  user,
});

export const fetchUserFail = (userError: HttpError) => ({
  type: actionTypes.FETCH_USER_FAIL,
  userError,
});

export const fetchUsersStart = () => ({
  type: actionTypes.FETCH_USERS_START,
});

export const fetchUsersSuccess = (usersList: ListResults<User>) => ({
  type: actionTypes.FETCH_USERS_SUCCESS,
  usersList,
});

export const fetchUsersFail = (usersError: HttpError) => ({
  type: actionTypes.FETCH_USERS_FAIL,
  usersError,
});

export const fetchAllUsersStart = () => ({
  type: actionTypes.FETCH_ALL_USERS_START,
});

export const fetchAllUsersSuccess = (allUsersList: ListResults<User>) => ({
  type: actionTypes.FETCH_ALL_USERS_SUCCESS,
  allUsersList,
});

export const fetchAllUsersFail = (allUsersError: HttpError) => ({
  type: actionTypes.FETCH_ALL_USERS_FAIL,
  allUsersError,
});

export const createUserStart = () => ({
  type: actionTypes.CREATE_USER_START,
});

export const createUserSuccess = (createdUser: User) => ({
  type: actionTypes.CREATE_USER_SUCCESS,
  createdUser,
});

export const createUserFail = (userCreateError: HttpError) => ({
  type: actionTypes.CREATE_USER_FAIL,
  userCreateError,
});

export const updateUserStart = () => ({
  type: actionTypes.UPDATE_USER_START,
});

export const updateUserSuccess = () => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
});

export const updateUserFail = (userUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_USER_FAIL,
  userUpdateError,
});

export const updateUserBalanceStart = () => ({
  type: actionTypes.UPDATE_USER_BALANCE_START,
});

export const updateUserBalanceSuccess = () => ({
  type: actionTypes.UPDATE_USER_BALANCE_SUCCESS,
});

export const updateUserBalanceFail = (userUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_USER_BALANCE_FAIL,
  userUpdateError,
});

export const deleteUserStart = () => ({
  type: actionTypes.DELETE_USER_START,
});

export const deleteUserSuccess = () => ({
  type: actionTypes.DELETE_USER_SUCCESS,
});

export const deleteUserFail = (cityUserError: HttpError) => ({
  type: actionTypes.DELETE_USER_FAIL,
  cityUserError,
});

export const fetchCurrentUserStart = () => ({
  type: actionTypes.FETCH_ME_START,
});

export const fetchCurrentUserSuccess = (currentUser: User) => ({
  type: actionTypes.FETCH_ME_SUCCESS,
  currentUser,
});

export const fetchCurrentUserFail = () => ({
  type: actionTypes.FETCH_ME_FAIL,
});

export const fetchHasPasswordStart = () => ({
  type: actionTypes.FETCH_HAS_PASSWORD_START,
});

export const fetchHasPasswordSuccess = (hasPassword: boolean) => ({
  type: actionTypes.FETCH_HAS_PASSWORD_SUCCESS,
  hasPassword,
});

export const fetchHasPasswordFail = () => ({
  type: actionTypes.FETCH_HAS_PASSWORD_FAIL,
});

export const updateUserInfoStart = () => ({
  type: actionTypes.UPDATE_USER_INFO_START,
});

export const updateUserInfoSuccess = () => ({
  type: actionTypes.UPDATE_USER_INFO_SUCCESS,
});

export const updateUserInfoFail = (userUpdateInfoError: HttpError) => ({
  type: actionTypes.UPDATE_USER_INFO_FAIL,
  userUpdateInfoError,
});

export const updateUserEmailStart = () => ({
  type: actionTypes.UPDATE_USER_EMAIL_START,
});

export const updateUserEmailSuccess = () => ({
  type: actionTypes.UPDATE_USER_EMAIL_SUCCESS,
});

export const updateUserEmailFail = (userEmailChangeError: HttpError) => ({
  type: actionTypes.UPDATE_USER_EMAIL_FAIL,
  userEmailChangeError,
});

export const resetUpdateUserInfo = () => ({
  type: actionTypes.RESET_USER_INFO_STORE,
});

export const checkIsUsernameTakenStart = () => ({
  type: actionTypes.CHECK_IS_USERNAME_TAKEN_START,
});

export const checkIsUsernameTakenSuccess = (isUsernameTaken: boolean) => ({
  type: actionTypes.CHECK_IS_USERNAME_TAKEN_SUCCESS,
  isUsernameTaken,
});

export const checkIsUsernameTakenFail = (isUsernameTakenError: HttpError) => ({
  type: actionTypes.CHECK_IS_USERNAME_TAKEN_FAIL,
  isUsernameTakenError,
});

export const updateUserPasswordStart = () => ({
  type: actionTypes.UPDATE_USER_PASSWORD_START,
});

export const updateUserPasswordSuccess = () => ({
  type: actionTypes.UPDATE_USER_PASSWORD_SUCCESS,
});

export const updateUserPasswordFail = (updateUserPasswordError: HttpError) => ({
  type: actionTypes.UPDATE_USER_PASSWORD_FAIL,
  updateUserPasswordError,
});

export const updateUserSocialMediaStart = () => ({
  type: actionTypes.UPDATE_USER_SOCIAL_MEDIA_START,
});

export const updateUserSocialMediaSuccess = () => ({
  type: actionTypes.UPDATE_USER_SOCIAL_MEDIA_SUCCESS,
});

export const updateUserSocialMediaFail = (
  updateUserSocialMediaError: HttpError,
) => ({
  type: actionTypes.UPDATE_USER_SOCIAL_MEDIA_FAIL,
  updateUserSocialMediaError,
});

export const refreshCurrentUserProfileStart = () => ({
  type: actionTypes.REFRESH_CURRENT_USER_PROFILE_START,
});

export const refreshCurrentUserProfileSuccess = (currentUser: User) => ({
  type: actionTypes.REFRESH_CURRENT_USER_PROFILE_SUCCESS,
  currentUser,
});

export const refreshCurrentUserProfileFail = () => ({
  type: actionTypes.REFRESH_CURRENT_USER_PROFILE_FAIL,
});

export const fetchInvitedUsersStart = () => ({
  type: actionTypes.FETCH_INVITED_USERS_START,
});

export const fetchInvitedUsersSuccess = (invitedUsers: User) => ({
  type: actionTypes.FETCH_INVITED_USERS_SUCCESS,
  invitedUsers,
});

export const fetchInvitedUsersFail = (invitedUsersError: HttpError) => ({
  type: actionTypes.FETCH_INVITED_USERS_FAIL,
  invitedUsersError,
});

export const fetchPublicUserProfileStart = () => ({
  type: actionTypes.FETCH_PUBLIC_USER_PROFILE_START,
});

export const fetchPublicUserProfileSuccess = (
  publicUserProfile: PublicUserProfile,
) => ({
  type: actionTypes.FETCH_PUBLIC_USER_PROFILE_SUCCESS,
  publicUserProfile,
});

export const fetchPublicUserProfileFail = (
  publicUserProfileError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_USER_PROFILE_FAIL,
  publicUserProfileError,
});

export const fetchPublicUserSocialStatsStart = () => ({
  type: actionTypes.FETCH_PUBLIC_USER_SOCIAL_STATS_START,
});

export const fetchPublicUserSocialStatsSuccess = (
  publicUserSocialStats: PublicUserSocialStats,
) => ({
  type: actionTypes.FETCH_PUBLIC_USER_SOCIAL_STATS_SUCCESS,
  publicUserSocialStats,
});

export const fetchPublicUserSocialStatsFail = (
  publicUserSocialStatsError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_USER_SOCIAL_STATS_FAIL,
  publicUserSocialStatsError,
});

export const fetchUserStatsStart = () => ({
  type: actionTypes.FETCH_USER_STATS_START,
});

export const fetchUserStatsSuccess = (userStats: UserStats) => ({
  type: actionTypes.FETCH_USER_STATS_SUCCESS,
  userStats,
});

export const fetchUserStatsFail = (userStatsError: HttpError) => ({
  type: actionTypes.FETCH_USER_STATS_FAIL,
  userStatsError,
});

export const resetPublicUserProfileStore = () => ({
  type: actionTypes.RESET_PUBLIC_USER_PROFILE_STORE,
});

export const fetchInvitedUsersSalesCountStart = () => ({
  type: actionTypes.FETCH_INVITED_USERS_SALES_COUNT_START,
});

export const fetchInvitedUsersSalesCountSuccess = (
  invitedUsersSalesCount: number,
) => ({
  type: actionTypes.FETCH_INVITED_USERS_SALES_COUNT_SUCCESS,
  invitedUsersSalesCount,
});

export const fetchInvitedUsersSalesCountFail = (
  invitedUsersSalesCountError: HttpError,
) => ({
  type: actionTypes.FETCH_INVITED_USERS_SALES_COUNT_FAIL,
  invitedUsersSalesCountError,
});

export const addToCart = (cartItems: CartItem[]) => ({
  type: actionTypes.ADD_TO_CART,
  cartItems,
});

export const userSelfDeleteStart = () => ({
  type: actionTypes.USER_SELF_DELETE_START,
});

export const userSelfDeleteSuccess = () => ({
  type: actionTypes.USER_SELF_DELETE_SUCCESS,
});

export const userSelfDeleteFail = (userSelfDeleteError: HttpError) => ({
  type: actionTypes.USER_SELF_DELETE_FAIL,
  userSelfDeleteError,
});

export const removeFromCart = (cartItems: CartItem[]) => ({
  type: actionTypes.REMOVE_FROM_CART,
  cartItems,
});

export const resetCartStore = () => ({
  type: actionTypes.RESET_CART_STORE,
});

export const addSetToUserCollectionStart = () => ({
  type: actionTypes.ADD_SET_TO_USER_COLLECTION_START,
});

export const addSetToUserCollectionSuccess = () => ({
  type: actionTypes.ADD_SET_TO_USER_COLLECTION_SUCCESS,
});

export const addSetToUserCollectionFail = (
  addSetToUserCollectionError: HttpError,
) => ({
  type: actionTypes.ADD_SET_TO_USER_COLLECTION_FAIL,
  addSetToUserCollectionError,
});

export const removeSelfAddedSetStart = () => ({
  type: actionTypes.REMOVE_SELF_ADDED_SET_START,
});

export const removeSelfAddedSetSuccess = () => ({
  type: actionTypes.REMOVE_SELF_ADDED_SET_SUCCESS,
});

export const removeSelfAddedSetFail = (removeSelfAddedSetError: HttpError) => ({
  type: actionTypes.REMOVE_SELF_ADDED_SET_FAIL,
  removeSelfAddedSetError,
});

export const fetchUserCollectionPartsStart = () => ({
  type: actionTypes.FETCH_USER_COLLECTION_PARTS_START,
});

export const fetchUserCollectionPartsSuccess = (
  userCollectionParts: UserCollectionParts,
) => ({
  type: actionTypes.FETCH_USER_COLLECTION_PARTS_SUCCESS,
  userCollectionParts,
});

export const fetchUserCollectionPartsFail = (
  userCollectionPartsError: HttpError,
) => ({
  type: actionTypes.FETCH_USER_COLLECTION_PARTS_FAIL,
  userCollectionPartsError,
});

export const fetchCanUserWithdrawStart = () => ({
  type: actionTypes.FETCH_CAN_USER_WITHDRAW_START,
});

export const fetchCanUserWithdrawSuccess = (canUserWithdraw: boolean) => ({
  type: actionTypes.FETCH_CAN_USER_WITHDRAW_SUCCESS,
  canUserWithdraw,
});

export const fetchCanUserWithdrawFail = () => ({
  type: actionTypes.FETCH_CAN_USER_WITHDRAW_FAIL,
});

export const resetAddSetToUserCollectionStore = () => ({
  type: actionTypes.RESET_ADD_SET_TO_USER_COLLECTION_STORE,
});

export const resetUserInfoStore = () => ({
  type: actionTypes.RESET_USER_INFO_STORE,
});

export const resetUserStore = () => ({
  type: actionTypes.RESET_USER_STORE,
});

export const resetCreateUserStore = () => ({
  type: actionTypes.RESET_CREATED_USER,
});

export const logout = () => ({
  type: actionTypes.LOGOUT,
});
