export const FETCH_PRODUCT_LIST_START = 'FETCH_PRODUCT_LIST_START';
export const FETCH_PRODUCT_LIST_SUCCESS = 'FETCH_PRODUCT_LIST_SUCCESS';
export const FETCH_PRODUCT_LIST_FAIL = 'FETCH_PRODUCT_LIST_FAIL';

export const FETCH_USER_LIKED_PRODUCT_LIST_START =
  'FETCH_USER_LIKED_PRODUCT_LIST_START';
export const FETCH_USER_LIKED_PRODUCT_LIST_SUCCESS =
  'FETCH_USER_LIKED_PRODUCT_LIST_SUCCESS';
export const FETCH_USER_LIKED_PRODUCT_LIST_FAIL =
  'FETCH_USER_LIKED_PRODUCT_LIST_FAIL';

export const INITIATE_PRODUCTS_FETCH_START = 'INITIATE_PRODUCTS_FETCH_START';
export const INITIATE_PRODUCTS_FETCH_SUCCESS =
  'INITIATE_PRODUCTS_FETCH_SUCCESS';
export const INITIATE_PRODUCTS_FETCH_FAIL = 'INITIATE_PRODUCTS_FETCH_FAIL';

export const FETCH_USER_PRODUCT_LIST_START = 'FETCH_USER_PRODUCT_LIST_START';
export const FETCH_USER_PRODUCT_LIST_SUCCESS =
  'FETCH_USER_PRODUCT_LIST_SUCCESS';
export const FETCH_USER_PRODUCT_LIST_FAIL = 'FETCH_USER_PRODUCT_LIST_FAIL';

export const FETCH_USER_ALL_PRODUCTS_COUNT_START =
  'FETCH_USER_ALL_PRODUCTS_COUNT_START';
export const FETCH_USER_ALL_PRODUCTS_COUNT_SUCCESS =
  'FETCH_USER_ALL_PRODUCTS_COUNT_SUCCESS';
export const FETCH_USER_ALL_PRODUCTS_COUNT_FAIL =
  'FETCH_USER_ALL_PRODUCTS_COUNT_FAIL';

export const FETCH_PRODUCT_START = 'FETCH_PRODUCT_START';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAIL = 'FETCH_PRODUCT_FAIL';
export const RESET_FETCH_PRODUCT = 'RESET_FETCH_PRODUCT';

export const CREATE_PRODUCT_START = 'CREATE_PRODUCT_START';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAIL = 'CREATE_PRODUCT_FAIL';
export const RESET_CREATE_AND_UPDATE_PRODUCT_SUCCESS =
  'RESET_CREATE_AND_UPDATE_PRODUCT_SUCCESS';

export const UPDATE_PRODUCT_START = 'UPDATE_PRODUCT_START';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';

export const DELETE_PRODUCT_START = 'DELETE_PRODUCT_START';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';

export const FETCH_PUBLIC_POPULAR_PRODUCTS_START =
  'FETCH_PUBLIC_POPULAR_PRODUCTS_START';
export const FETCH_PUBLIC_POPULAR_PRODUCTS_SUCCESS =
  'FETCH_PUBLIC_POPULAR_PRODUCTS_SUCCESS';
export const FETCH_PUBLIC_POPULAR_PRODUCTS_FAIL =
  'FETCH_PUBLIC_POPULAR_PRODUCTS_FAIL';

export const FETCH_PUBLIC_PRODUCTS_START = 'FETCH_PUBLIC_PRODUCTS_START';
export const FETCH_PUBLIC_PRODUCTS_SUCCESS = 'FETCH_PUBLIC_PRODUCTS_SUCCESS';
export const FETCH_PUBLIC_PRODUCTS_FAIL = 'FETCH_PUBLIC_PRODUCTS_FAIL';

export const FETCH_PUBLIC_SET_PRODUCTS_START =
  'FETCH_PUBLIC_SET_PRODUCTS_START';
export const FETCH_PUBLIC_SET_PRODUCTS_SUCCESS =
  'FETCH_PUBLIC_SET_PRODUCTS_SUCCESS';
export const FETCH_PUBLIC_SET_PRODUCTS_FAIL = 'FETCH_PUBLIC_SET_PRODUCTS_FAIL';

export const FETCH_PUBLIC_SET_PRODUCTS_FOR_SALE_START =
  'FETCH_PUBLIC_SET_PRODUCTS_FOR_SALE_START';
export const FETCH_PUBLIC_SET_PRODUCTS_FOR_SALE_SUCCESS =
  'FETCH_PUBLIC_SET_PRODUCTS_FOR_SALE_SUCCESS';
export const FETCH_PUBLIC_SET_PRODUCTS_FOR_SALE_FAIL =
  'FETCH_PUBLIC_SET_PRODUCTS_FOR_SALE_FAIL';

export const FETCH_PUBLIC_BUNDLES_START = 'FETCH_PUBLIC_BUNDLES_START';
export const FETCH_PUBLIC_BUNDLES_SUCCESS = 'FETCH_PUBLIC_BUNDLES_SUCCESS';
export const FETCH_PUBLIC_BUNDLES_FAIL = 'FETCH_PUBLIC_BUNDLES_FAIL';

export const FETCH_PUBLIC_BUNDLE_SECTIONS_START =
  'FETCH_PUBLIC_BUNDLE_SECTIONS_START';
export const FETCH_PUBLIC_BUNDLE_SECTIONS_SUCCESS =
  'FETCH_PUBLIC_BUNDLE_SECTIONS_SUCCESS';
export const FETCH_PUBLIC_BUNDLE_SECTIONS_FAIL =
  'FETCH_PUBLIC_BUNDLE_SECTIONS_FAIL';

export const FETCH_PUBLIC_SET_OPTIONS_START = 'FETCH_PUBLIC_SET_OPTIONS_START';
export const FETCH_PUBLIC_SET_OPTIONS_SUCCESS =
  'FETCH_PUBLIC_SET_OPTIONS_SUCCESS';
export const FETCH_PUBLIC_SET_OPTIONS_FAIL = 'FETCH_PUBLIC_SET_OPTIONS_FAIL';

export const RESET_FETCH_SET_OPTIONS = 'RESET_FETCH_SET_OPTIONS';

export const FETCH_PUBLIC_PRODUCT_START = 'FETCH_PUBLIC_PRODUCT_START';
export const FETCH_PUBLIC_PRODUCT_SUCCESS = 'FETCH_PUBLIC_PRODUCT_SUCCESS';
export const FETCH_PUBLIC_PRODUCT_FAIL = 'FETCH_PUBLIC_PRODUCT_FAIL';
export const RESET_PUBLIC_PRODUCT = 'RESET_PUBLIC_PRODUCT';

export const UPDATE_PRODUCT_FILES_START = 'UPDATE_PRODUCT_FILES_START';
export const UPDATE_PRODUCT_FILES_SUCCESS = 'UPDATE_PRODUCT_FILES_SUCCESS';
export const UPDATE_PRODUCT_FILES_FAIL = 'UPDATE_PRODUCT_FILES_FAIL';

export const UPDATE_STUDIO_IO_FILE_START = 'UPDATE_STUDIO_IO_FILE_START';
export const UPDATE_STUDIO_IO_FILE_SUCCESS = 'UPDATE_STUDIO_IO_FILE_SUCCESS';
export const UPDATE_STUDIO_IO_FILE_FAIL = 'UPDATE_STUDIO_IO_FILE_FAIL';

export const UPDATE_INSTRUCTIONS_FILE_START = 'UPDATE_INSTRUCTIONS_FILE_START';
export const UPDATE_INSTRUCTIONS_FILE_SUCCESS =
  'UPDATE_INSTRUCTIONS_FILE_SUCCESS';
export const UPDATE_INSTRUCTIONS_FILE_FAIL = 'UPDATE_INSTRUCTIONS_FILE_FAIL';

export const UPDATE_ADDITIONAL_INSTRUCTIONS_FILE_START =
  'UPDATE_ADDITIONAL_INSTRUCTIONS_FILE_START';
export const UPDATE_ADDITIONAL_INSTRUCTIONS_FILE_SUCCESS =
  'UPDATE_ADDITIONAL_INSTRUCTIONS_FILE_SUCCESS';
export const UPDATE_ADDITIONAL_INSTRUCTIONS_FILE_FAIL =
  'UPDATE_ADDITIONAL_INSTRUCTIONS_FILE_FAIL';

export const UPDATE_LDR_FILE_START = 'UPDATE_LDR_FILE_START';
export const UPDATE_LDR_FILE_SUCCESS = 'UPDATE_LDR_FILE_SUCCESS';
export const UPDATE_LDR_FILE_FAIL = 'UPDATE_LDR_FILE_FAIL';

export const UPDATE_PARTS_FILE_START = 'UPDATE_PARTS_FILE_START';
export const UPDATE_PARTS_FILE_SUCCESS = 'UPDATE_PARTS_FILE_SUCCESS';
export const UPDATE_PARTS_FILE_FAIL = 'UPDATE_PARTS_FILE_FAIL';

export const RESET_UPDATE_PRODUCT_FILES = 'RESET_UPDATE_PRODUCT_FILES';

export const PUBLISH_PRODUCT_START = 'PUBLISH_PRODUCT_START';
export const PUBLISH_PRODUCT_SUCCESS = 'PUBLISH_PRODUCT_SUCCESS';
export const PUBLISH_PRODUCT_FAIL = 'PUBLISH_PRODUCT_FAIL';
export const RESET_PUBLISH_PRODUCT = 'RESET_PUBLISH_PRODUCT';

export const DEPUBLISH_PRODUCT_START = 'DEPUBLISH_PRODUCT_START';
export const DEPUBLISH_PRODUCT_SUCCESS = 'DEPUBLISH_PRODUCT_SUCCESS';
export const DEPUBLISH_PRODUCT_FAIL = 'DEPUBLISH_PRODUCT_FAIL';

export const SET_PUBLIC_PRODUCTS_PARAMS = 'SET_PUBLIC_PRODUCTS_PARAMS';
export const SET_ADMIN_PRODUCTS_PARAMS = 'SET_ADMIN_PRODUCTS_PARAMS';

export const ADD_PURCHASED_PRODUCT_PROMOTION =
  'ADD_PURCHASED_PRODUCT_PROMOTION';

export const FETCH_RECOMMENDED_PRODUCTS_START =
  'FETCH_RECOMMENDED_PRODUCTS_START';
export const FETCH_RECOMMENDED_PRODUCTS_SUCCESS =
  'FETCH_RECOMMENDED_PRODUCTS_SUCCESS';
export const FETCH_RECOMMENDED_PRODUCTS_FAIL =
  'FETCH_RECOMMENDED_PRODUCTS_FAIL';

export const FETCH_PUBLIC_USER_CREATIONS_LIST_START =
  'FETCH_PUBLIC_USER_CREATIONS_LIST_START';
export const FETCH_PUBLIC_USER_CREATIONS_LIST_SUCCESS =
  'FETCH_PUBLIC_USER_CREATIONS_LIST_SUCCESS';
export const FETCH_PUBLIC_USER_CREATIONS_LIST_FAIL =
  'FETCH_PUBLIC_USER_CREATIONS_LIST_FAIL';

export const FETCH_VERIFIED_PURCHASE_LIST_START =
  'FETCH_VERIFIED_PURCHASE_LIST_START';
export const FETCH_VERIFIED_PURCHASE_LIST_SUCCESS =
  'FETCH_VERIFIED_PURCHASE_LIST_SUCCESS';
export const FETCH_VERIFIED_PURCHASE_LIST_FAIL =
  'FETCH_VERIFIED_PURCHASE_LIST_FAIL';

export const FETCH_SELF_ADDED_PRODUCTS_LIST_START =
  'FETCH_SELF_ADDED_PRODUCTS_LIST_START';
export const FETCH_SELF_ADDED_PRODUCTS_LIST_SUCCESS =
  'FETCH_SELF_ADDED_PRODUCTS_LIST_SUCCESS';
export const FETCH_SELF_ADDED_PRODUCTS_LIST_FAIL =
  'FETCH_SELF_ADDED_PRODUCTS_LIST_FAIL';

export const FETCH_USER_SET_OPTIONS_START = 'FETCH_USER_SET_OPTIONS_START';
export const FETCH_USER_SET_OPTIONS_SUCCESS = 'FETCH_USER_SET_OPTIONS_SUCCESS';
export const FETCH_USER_SET_OPTIONS_FAIL = 'FETCH_USER_SET_OPTIONS_FAIL';

export const FETCH_USER_SET_ALTS_START = 'FETCH_USER_SET_ALTS_START';
export const FETCH_USER_SET_ALTS_SUCCESS = 'FETCH_USER_SET_ALTS_SUCCESS';
export const FETCH_USER_SET_ALTS_FAIL = 'FETCH_USER_SET_ALTS_FAIL';
export const RESET_USER_SET_ALTS = 'RESET_USER_SET_ALTS';

export const FETCH_SETS_SUGGESTIONS_START = 'FETCH_SETS_SUGGESTIONS_START';
export const FETCH_SETS_SUGGESTIONS_SUCCESS = 'FETCH_SETS_SUGGESTIONS_SUCCESS';
export const FETCH_SETS_SUGGESTIONS_FAIL = 'FETCH_SETS_SUGGESTIONS_FAIL';
export const RESET_PARTICIPANTS_SUGGESTIONS = 'RESET_PARTICIPANTS_SUGGESTIONS';

export const DOWNLOAD_ALL_ASSETS_START = 'DOWNLOAD_ALL_ASSETS_START';
export const DOWNLOAD_ALL_ASSETS_SUCCESS = 'DOWNLOAD_ALL_ASSETS_SUCCESS';
export const DOWNLOAD_ALL_ASSETS_FAIL = 'DOWNLOAD_ALL_ASSETS_FAIL';

export const FETCH_USER_PURCHASED_PRODUCTS_START =
  'FETCH_USER_PURCHASED_PRODUCTS_START';
export const FETCH_USER_PURCHASED_PRODUCTS_SUCCESS =
  'FETCH_USER_PURCHASED_PRODUCTS_SUCCESS';
export const FETCH_USER_PURCHASED_PRODUCTS_FAIL =
  'FETCH_USER_PURCHASED_PRODUCTS_FAIL';

export const FETCH_ALL_SETS_START = 'FETCH_ALL_SETS_START';
export const FETCH_ALL_SETS_SUCCESS = 'FETCH_ALL_SETS_SUCCESS';
export const FETCH_ALL_SETS_FAIL = 'FETCH_ALL_SETS_FAIL';

export const FETCH_BUNDLE_SET_PRODUCT_START = 'FETCH_BUNDLE_SET_PRODUCT_START';
export const FETCH_BUNDLE_SET_PRODUCT_SUCCESS =
  'FETCH_BUNDLE_SET_PRODUCT_SUCCESS';
export const FETCH_BUNDLE_SET_PRODUCT_FAIL = 'FETCH_BUNDLE_SET_PRODUCT_FAIL';

export const FETCH_EXPLORE_SETS_START = 'FETCH_EXPLORE_SETS_START';
export const FETCH_EXPLORE_SETS_SUCCESS = 'FETCH_EXPLORE_SETS_SUCCESS';
export const FETCH_EXPLORE_SETS_FAIL = 'FETCH_EXPLORE_SETS_FAIL';

export const TOGGLE_USER_LIKED_PRODUCT_START =
  'TOGGLE_USER_LIKED_PRODUCT_START';
export const TOGGLE_USER_LIKED_PRODUCT_SUCCESS =
  'TOGGLE_USER_LIKED_PRODUCT_SUCCESS';
export const TOGGLE_USER_LIKED_PRODUCT_FAIL = 'TOGGLE_USER_LIKED_PRODUCT_FAIL';

export const FETCH_USER_LIKED_PRODUCTS_START =
  'FETCH_USER_LIKED_PRODUCTS_START';
export const FETCH_USER_LIKED_PRODUCTS_SUCCESS =
  'FETCH_USER_LIKED_PRODUCTS_SUCCESS';
export const FETCH_USER_LIKED_PRODUCTS_FAIL = 'FETCH_USER_LIKED_PRODUCTS_FAIL';

export const RESET_CREATED_PRODUCT_STORE = 'RESET_CREATED_PRODUCT_STORE';
export const RESET_PRODUCT_STORE = 'RESET_PRODUCT_STORE';

export const LOGOUT = 'LOGOUT';

export type ProductActionTypes =
  | typeof FETCH_PRODUCT_LIST_START
  | typeof FETCH_PRODUCT_LIST_SUCCESS
  | typeof FETCH_PRODUCT_LIST_FAIL
  | typeof FETCH_PUBLIC_BUNDLES_START
  | typeof FETCH_PUBLIC_BUNDLES_SUCCESS
  | typeof FETCH_PUBLIC_BUNDLES_FAIL
  | typeof FETCH_PUBLIC_BUNDLE_SECTIONS_START
  | typeof FETCH_PUBLIC_BUNDLE_SECTIONS_SUCCESS
  | typeof FETCH_PUBLIC_BUNDLE_SECTIONS_FAIL
  | typeof FETCH_USER_SET_OPTIONS_START
  | typeof FETCH_USER_SET_OPTIONS_SUCCESS
  | typeof FETCH_USER_SET_OPTIONS_FAIL
  | typeof FETCH_USER_SET_ALTS_START
  | typeof FETCH_USER_SET_ALTS_SUCCESS
  | typeof FETCH_USER_SET_ALTS_FAIL
  | typeof FETCH_PUBLIC_SET_PRODUCTS_START
  | typeof FETCH_PUBLIC_SET_PRODUCTS_SUCCESS
  | typeof FETCH_PUBLIC_SET_PRODUCTS_FAIL
  | typeof FETCH_PUBLIC_SET_PRODUCTS_FOR_SALE_START
  | typeof FETCH_PUBLIC_SET_PRODUCTS_FOR_SALE_SUCCESS
  | typeof FETCH_PUBLIC_SET_PRODUCTS_FOR_SALE_FAIL
  | typeof FETCH_USER_LIKED_PRODUCT_LIST_START
  | typeof FETCH_USER_LIKED_PRODUCT_LIST_SUCCESS
  | typeof FETCH_USER_LIKED_PRODUCT_LIST_FAIL
  | typeof INITIATE_PRODUCTS_FETCH_START
  | typeof INITIATE_PRODUCTS_FETCH_SUCCESS
  | typeof INITIATE_PRODUCTS_FETCH_FAIL
  | typeof UPDATE_PRODUCT_FILES_START
  | typeof UPDATE_PRODUCT_FILES_SUCCESS
  | typeof UPDATE_PRODUCT_FILES_FAIL
  | typeof UPDATE_STUDIO_IO_FILE_START
  | typeof UPDATE_STUDIO_IO_FILE_SUCCESS
  | typeof UPDATE_STUDIO_IO_FILE_FAIL
  | typeof UPDATE_INSTRUCTIONS_FILE_START
  | typeof UPDATE_INSTRUCTIONS_FILE_SUCCESS
  | typeof UPDATE_INSTRUCTIONS_FILE_FAIL
  | typeof UPDATE_ADDITIONAL_INSTRUCTIONS_FILE_START
  | typeof UPDATE_ADDITIONAL_INSTRUCTIONS_FILE_SUCCESS
  | typeof UPDATE_ADDITIONAL_INSTRUCTIONS_FILE_FAIL
  | typeof UPDATE_LDR_FILE_START
  | typeof UPDATE_LDR_FILE_SUCCESS
  | typeof UPDATE_LDR_FILE_FAIL
  | typeof UPDATE_PARTS_FILE_START
  | typeof UPDATE_PARTS_FILE_SUCCESS
  | typeof UPDATE_PARTS_FILE_FAIL
  | typeof RESET_UPDATE_PRODUCT_FILES
  | typeof FETCH_USER_PRODUCT_LIST_START
  | typeof FETCH_USER_PRODUCT_LIST_SUCCESS
  | typeof FETCH_USER_PRODUCT_LIST_FAIL
  | typeof FETCH_USER_ALL_PRODUCTS_COUNT_START
  | typeof FETCH_USER_ALL_PRODUCTS_COUNT_SUCCESS
  | typeof FETCH_USER_ALL_PRODUCTS_COUNT_FAIL
  | typeof FETCH_PRODUCT_START
  | typeof FETCH_PRODUCT_SUCCESS
  | typeof FETCH_PRODUCT_FAIL
  | typeof CREATE_PRODUCT_START
  | typeof CREATE_PRODUCT_SUCCESS
  | typeof CREATE_PRODUCT_FAIL
  | typeof RESET_FETCH_PRODUCT
  | typeof RESET_CREATE_AND_UPDATE_PRODUCT_SUCCESS
  | typeof UPDATE_PRODUCT_START
  | typeof UPDATE_PRODUCT_SUCCESS
  | typeof UPDATE_PRODUCT_FAIL
  | typeof DELETE_PRODUCT_START
  | typeof DELETE_PRODUCT_SUCCESS
  | typeof DELETE_PRODUCT_FAIL
  | typeof FETCH_PUBLIC_POPULAR_PRODUCTS_START
  | typeof FETCH_PUBLIC_POPULAR_PRODUCTS_SUCCESS
  | typeof FETCH_PUBLIC_POPULAR_PRODUCTS_FAIL
  | typeof FETCH_PUBLIC_PRODUCTS_START
  | typeof FETCH_PUBLIC_PRODUCTS_SUCCESS
  | typeof FETCH_PUBLIC_PRODUCTS_FAIL
  | typeof FETCH_PUBLIC_PRODUCT_START
  | typeof FETCH_PUBLIC_PRODUCT_SUCCESS
  | typeof FETCH_PUBLIC_PRODUCT_FAIL
  | typeof RESET_PUBLIC_PRODUCT
  | typeof PUBLISH_PRODUCT_START
  | typeof PUBLISH_PRODUCT_SUCCESS
  | typeof PUBLISH_PRODUCT_FAIL
  | typeof RESET_PUBLISH_PRODUCT
  | typeof DEPUBLISH_PRODUCT_START
  | typeof DEPUBLISH_PRODUCT_SUCCESS
  | typeof DEPUBLISH_PRODUCT_FAIL
  | typeof SET_PUBLIC_PRODUCTS_PARAMS
  | typeof SET_ADMIN_PRODUCTS_PARAMS
  | typeof FETCH_RECOMMENDED_PRODUCTS_START
  | typeof FETCH_RECOMMENDED_PRODUCTS_SUCCESS
  | typeof FETCH_RECOMMENDED_PRODUCTS_FAIL
  | typeof FETCH_PUBLIC_USER_CREATIONS_LIST_START
  | typeof FETCH_PUBLIC_USER_CREATIONS_LIST_SUCCESS
  | typeof FETCH_PUBLIC_USER_CREATIONS_LIST_FAIL
  | typeof FETCH_VERIFIED_PURCHASE_LIST_START
  | typeof FETCH_VERIFIED_PURCHASE_LIST_SUCCESS
  | typeof FETCH_VERIFIED_PURCHASE_LIST_FAIL
  | typeof FETCH_SETS_SUGGESTIONS_START
  | typeof FETCH_SETS_SUGGESTIONS_SUCCESS
  | typeof FETCH_SETS_SUGGESTIONS_FAIL
  | typeof FETCH_SELF_ADDED_PRODUCTS_LIST_START
  | typeof FETCH_SELF_ADDED_PRODUCTS_LIST_SUCCESS
  | typeof FETCH_SELF_ADDED_PRODUCTS_LIST_FAIL
  | typeof FETCH_PUBLIC_SET_OPTIONS_START
  | typeof FETCH_PUBLIC_SET_OPTIONS_SUCCESS
  | typeof FETCH_PUBLIC_SET_OPTIONS_FAIL
  | typeof RESET_FETCH_SET_OPTIONS
  | typeof RESET_PARTICIPANTS_SUGGESTIONS
  | typeof ADD_PURCHASED_PRODUCT_PROMOTION
  | typeof DOWNLOAD_ALL_ASSETS_START
  | typeof DOWNLOAD_ALL_ASSETS_SUCCESS
  | typeof DOWNLOAD_ALL_ASSETS_FAIL
  | typeof FETCH_USER_PURCHASED_PRODUCTS_START
  | typeof FETCH_USER_PURCHASED_PRODUCTS_SUCCESS
  | typeof FETCH_USER_PURCHASED_PRODUCTS_FAIL
  | typeof RESET_CREATED_PRODUCT_STORE
  | typeof RESET_USER_SET_ALTS
  | typeof RESET_PRODUCT_STORE
  | typeof FETCH_ALL_SETS_START
  | typeof FETCH_ALL_SETS_SUCCESS
  | typeof FETCH_ALL_SETS_FAIL
  | typeof FETCH_BUNDLE_SET_PRODUCT_START
  | typeof FETCH_BUNDLE_SET_PRODUCT_SUCCESS
  | typeof FETCH_BUNDLE_SET_PRODUCT_FAIL
  | typeof FETCH_EXPLORE_SETS_START
  | typeof FETCH_EXPLORE_SETS_SUCCESS
  | typeof FETCH_EXPLORE_SETS_FAIL
  | typeof TOGGLE_USER_LIKED_PRODUCT_START
  | typeof TOGGLE_USER_LIKED_PRODUCT_SUCCESS
  | typeof TOGGLE_USER_LIKED_PRODUCT_FAIL
  | typeof FETCH_USER_LIKED_PRODUCTS_START
  | typeof FETCH_USER_LIKED_PRODUCTS_SUCCESS
  | typeof FETCH_USER_LIKED_PRODUCTS_FAIL
  | typeof LOGOUT;
