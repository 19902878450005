import * as actionTypes from './actionTypes';
import { OrderActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Order } from '../../domain/Order';
import { ListResults } from '../../common/List/List';

export type OrderStateType = {
  orderList: ListResults<Order> | null;
  orderListLoading: boolean;
  orderListError: HttpError;
  orderListUpdateNeeded: boolean;
  orderCreateLoading: boolean;
  orderCreateError: HttpError;
  orderCreateSuccess: boolean;
  createdOrder: Order | null;
  userOrdersList: ListResults<Order> | null;
  userOrdersListLoading: boolean;
  userOrdersListError: HttpError;
  userOrdersLoading: boolean;
  userOrders: Order[] | null;
  userOrdersError: HttpError;
};

export type OrderActionType = OrderStateType & {
  type: OrderActionTypes;
};

export const initialState: OrderStateType = {
  orderList: null,
  orderListLoading: true,
  orderListError: null,
  orderListUpdateNeeded: false,
  orderCreateLoading: false,
  orderCreateError: null,
  orderCreateSuccess: false,
  createdOrder: null,
  userOrdersList: null,
  userOrdersListError: null,
  userOrdersListLoading: false,
  userOrdersLoading: false,
  userOrders: null,
  userOrdersError: null,
};

const fetchOrderListStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  orderListLoading: true,
});

const fetchOrderListSuccess = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  orderListLoading: false,
  orderList: action.orderList,
  orderListUpdateNeeded: false,
});

const fetchOrderListFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  orderListLoading: false,
  orderListError: action.orderListError,
});

const createOrderStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  orderCreateLoading: true,
});

const createOrderSuccess = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  orderCreateError: null,
  orderCreateSuccess: true,
  createdOrder: action.createdOrder,
});

const createOrderFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  orderCreateLoading: false,
  orderCreateError: action.orderCreateError,
});

const fetchUserOrdersStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  userOrdersListLoading: true,
});

const fetchUserOrdersSuccess = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  userOrdersList: action.userOrdersList,
  userOrdersListLoading: false,
  userOrdersListError: null,
});

const fetchUserOrdersFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  userOrdersListError: action.userOrdersListError,
  userOrdersListLoading: false,
});

const fetchAllUserOrdersStart = (state: OrderStateType): OrderStateType => ({
  ...state,
  userOrdersLoading: true,
});

const fetchAllUserOrdersSuccess = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  userOrdersLoading: false,
  userOrders: action.userOrders,
});

const fetchAllUserOrdersFail = (
  state: OrderStateType,
  action: OrderActionType,
): OrderStateType => ({
  ...state,
  userOrdersLoading: false,
  userOrdersError: action.userOrdersError,
});

const resetOrderStore = (state: OrderStateType): OrderStateType => ({
  ...state,
  orderCreateLoading: false,
  orderCreateError: null,
  orderCreateSuccess: false,
  createdOrder: null,
});

const completelyResetOrderStore = (): OrderStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: OrderActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDER_LIST_START:
      return fetchOrderListStart(state);
    case actionTypes.FETCH_ORDER_LIST_SUCCESS:
      return fetchOrderListSuccess(state, action);
    case actionTypes.FETCH_ORDER_LIST_FAIL:
      return fetchOrderListFail(state, action);
    case actionTypes.CREATE_ORDER_START:
      return createOrderStart(state);
    case actionTypes.CREATE_ORDER_SUCCESS:
      return createOrderSuccess(state, action);
    case actionTypes.CREATE_ORDER_FAIL:
      return createOrderFail(state, action);
    case actionTypes.FETCH_USER_ORDERS_START:
      return fetchUserOrdersStart(state);
    case actionTypes.FETCH_USER_ORDERS_SUCCESS:
      return fetchUserOrdersSuccess(state, action);
    case actionTypes.FETCH_USER_ORDERS_FAIL:
      return fetchUserOrdersFail(state, action);
    case actionTypes.FETCH_ALL_USER_ORDERS_START:
      return fetchAllUserOrdersStart(state);
    case actionTypes.FETCH_ALL_USER_ORDERS_SUCCESS:
      return fetchAllUserOrdersSuccess(state, action);
    case actionTypes.FETCH_ALL_USER_ORDERS_FAIL:
      return fetchAllUserOrdersFail(state, action);
    case actionTypes.RESET_ORDER_STORE:
      return resetOrderStore(state);
    case actionTypes.LOGOUT:
      return completelyResetOrderStore();
    default:
      return state;
  }
};

export default reducer;
